import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Divider, Button, Select } from 'antd';

import {
  useGetTemplatesByClientQuery,
  TemplateFragment,
  InfoFragment,
  useUpdateInfoMutation,
} from '@shared/api';
import { SaveOutlined } from '@ant-design/icons';

import { useLoggedClient } from 'features/auth';
import { AppForm, Loader } from 'components';
import { useEntity } from 'hooks/useEntity';

const { Option } = Select;

type FormValues = {
  templateId: string;
};

interface InfosProps {
  info: InfoFragment;
}

export const TemplateInfoSelection = ({ info }: InfosProps) => {
  const { t } = useTranslation();
  const { id } = useLoggedClient();
  const [updateInfoMutation] = useUpdateInfoMutation();
  const entity = useEntity();
  const [form] = Form.useForm<FormValues>();

  const { data, loading } = useGetTemplatesByClientQuery({
    variables: { clientId: id, entityTypeId: entity.id },
  });

  const onSubmit = async (values: any) => {
    if (info) {
      await updateInfoMutation({
        variables: {
          id: info?.id,
          updates: { template_id: values.templateId },
        },
      });
    }
  };

  const initialValues = {
    templateId: info?.template_id,
  };

  return (
    <AppForm form={form} initialValues={initialValues} onFinish={onSubmit}>
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item
            label={t('templates.form.select')}
            name="templateId"
            rules={[{ required: true }]}
          >
            {data && (
              <Select style={{ width: '100%' }}>
                {data?.templates.map((template: TemplateFragment) => (
                  <Option key={template.id} value={template.id}>
                    {template.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row justify="end">
        <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
          {loading ? <Loader /> : t('common.confirm')}
        </Button>
      </Row>
    </AppForm>
  );
};
