import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { ValidationError } from 'yup';
import styled from 'styled-components';

import { passwordSchema } from '@shared/api';

import { translateError } from 'utils/errorUtils';
import { useTheme } from 'context/ThemePContext';

interface Props {
  email?: boolean;
  password?: boolean;
  confirmPassword?: boolean;
}

export const EmailPasswordItems = ({
  email = true,
  password = true,
  confirmPassword = false,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme(); // <- use the theme here

  const ColoredUserIcon = styled(UserOutlined)`
    color: ${theme.colors.primary};
  `;
  const ColoredLockIcon = styled(LockOutlined)`
    color: ${theme.colors.primary};
  `;

  return (
    <>
      {email && (
        <Form.Item name="email" rules={[{ type: 'email', required: true }]}>
          <Input prefix={<ColoredUserIcon />} placeholder={t('user.email')} />
        </Form.Item>
      )}
      {password && (
        <Form.Item
          name="password"
          rules={[
            {
              validator: (_, value) =>
                passwordSchema.validate({ password: value }).catch(error => {
                  if (error instanceof ValidationError) {
                    const errors = error.errors.map(e => translateError(e, t));
                    throw errors;
                  }
                  throw error;
                }),
            },
          ]}
        >
          <Input.Password
            prefix={<ColoredLockIcon />}
            placeholder={t('user.password')}
          />
        </Form.Item>
      )}
      {confirmPassword && (
        <Form.Item
          name="confirmPassword"
          dependencies={['password']}
          rules={[
            ({ getFieldValue }) => ({
              validator: (_, value) => {
                if (getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  t('form.errors.confirmPassword.different'),
                );
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<ColoredLockIcon />}
            placeholder={t('user.confirmPassword')}
          />
        </Form.Item>
      )}
    </>
  );
};
