import React, { useState } from 'react';
import { Drawer, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import { CompetitionData } from './types';
import { CompetitionFormSetup } from './Form/Setup';

const { TabPane } = Tabs;

export type CompetitionDrawerProps = {
  visible: boolean;
  competition?: CompetitionData;
  onAdd: (competition: CompetitionData) => void;
  onClose: () => void;
};

export const CompetitionDrawer = ({
  visible,
  competition,
  onAdd,
  onClose,
}: CompetitionDrawerProps) => {
  const { t } = useTranslation();
  const [tabActiveKey, setTabActiveKey] = useState('1');

  return (
    <Drawer
      title={competition?.name ? competition.name : t('competition.new')}
      width={720}
      onClose={onClose}
      visible={visible}
      destroyOnClose
    >
      <Tabs activeKey={tabActiveKey} onChange={setTabActiveKey}>
        <TabPane tab={t('competition.setup')} key="1">
          <CompetitionFormSetup competition={competition} onAdd={onAdd} />
        </TabPane>
      </Tabs>
    </Drawer>
  );
};
