import React from 'react';
import styled from 'styled-components';
import { Image } from 'antd';

import { useEntity } from 'hooks/useEntity';

export const AuthLayout = ({ children }: ChildrenProps) => {
  const entity = useEntity();

  return (
    <Container>
      <RightContainer>
        <FormContainer>
          <Logo src={entity.logoVertical} width="15rem" preview={false} />
          {children}
        </FormContainer>
      </RightContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  display: flex;
`;

const RightContainer = styled.div`
  flex: 1;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormContainer = styled.div`
  width: 25rem;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled(Image)`
  margin-bottom: 4rem;
`;
