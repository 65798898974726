import React, { createContext, FC, useContext } from 'react';
import { Entities, EntityType } from 'utils/entityType';

export const ThemeContext = createContext<EntityType | undefined>(undefined);

const ENTITY_NAME = process.env.REACT_APP_ENTITY_NAME || 'FlashPlay';
const entity = Entities[ENTITY_NAME] as EntityType;

export const ThemeProvider: FC = ({
  children,
}: {
  children?: React.ReactNode;
}) => <ThemeContext.Provider value={entity}>{children}</ThemeContext.Provider>;

export const useTheme = (): EntityType => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('ThemeContext not provided!');
  }
  return context;
};
