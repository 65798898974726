import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { RangeValue } from 'rc-picker/lib/interface';

import {
  CompetitionFragmentDoc,
  useGetEventsQuery,
  useGetLocationsQuery,
  useInsertCompetitionMutation,
  useUpdateCompetitionMutation,
} from '@shared/api';

import { AppForm, DateRangePicker } from 'components';
import { useLoggedClient } from 'features/auth';
import { useEntity } from 'hooks/useEntity';
import { CompetitionData } from '../types';

type FormValues = {
  name: string;
  description: string;
  rangeDate: string[];
  gameType: string;
  gameFormat: string;
  holesNumber: string;
  numberOfTurns: string;
  linkWebsite?: string;
  linkCompetition?: string;
};

interface CompetitionsFormDetailsProps {
  competition?: CompetitionData;
  onAdd: (competition: CompetitionData) => void;
}

export const CompetitionFormSetup = ({
  competition,
  onAdd,
}: CompetitionsFormDetailsProps) => {
  const { t } = useTranslation();
  const { id: clientId, role } = useLoggedClient();
  const [form] = Form.useForm<FormValues>();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonMessage, setButtonMessage] = useState(t('common.confirm'));
  const entity = useEntity();
  const [selectedLocationId, setSelectedLocationId] = useState<
    string | undefined
  >(competition?.location_id || undefined);
  const [selectedeventId, setSelectedEventId] = useState<string | undefined>(
    competition?.event_id || undefined,
  );
  const eventDates: [Date, Date] = [
    new Date(competition?.date_start || new Date()),
    new Date(competition?.date_end || new Date()),
  ];
  const [rangeDate, setRangeDate] = useState<RangeValue<Date>>(eventDates);
  const {
    data: locationsData,
    loading: locationsLoading,
  } = useGetLocationsQuery({
    variables: { entityTypeId: entity.id },
  });

  const { data: eventsData } = useGetEventsQuery({
    variables: {
      where: {
        _and: [
          {
            entity_type_id: { _eq: entity.id },
            is_archive: { _eq: false },
          },
        ],
      },
    },
  });

  const gameFormatsOptions = useMemo(
    () => [
      { label: t('competition.gameFormats.STABLEFORD'), value: 'STABLEFORD' },
      { label: t('competition.gameFormats.STROKE_PLAY'), value: 'STROKE_PLAY' },
      {
        label: t('competition.gameFormats.MAXIMUM_SCORE'),
        value: 'MAXIMUM_SCORE',
      },
    ],
    [t],
  );
  const gameTypesOptions = useMemo(
    () => [
      { label: t('competition.gameTypes.CHAPMAN'), value: 'CHAPMAN' },
      { label: t('competition.gameTypes.FOURSOME'), value: 'FOURSOME' },
      { label: t('competition.gameTypes.GREENSOME'), value: 'GREENSOME' },
      { label: t('competition.gameTypes.PATSOME'), value: 'PATSOME' },
      { label: t('competition.gameTypes.PRO_AM_AM'), value: 'PRO_AM_AM' },
      { label: t('competition.gameTypes.FOURBALL'), value: 'FOURBALL' },
      {
        label: t('competition.gameTypes.FOURBALL_AGGREGATE'),
        value: 'FOURBALL_AGGREGATE',
      },
      { label: t('competition.gameTypes.SCRAMBLE'), value: 'SCRAMBLE' },
      { label: t('competition.gameTypes.SIMPLE'), value: 'SIMPLE' },
    ],
    [t],
  );

  const holesNumberOptions = useMemo(
    () => [
      { label: t('competition.holesNumber.NINE_HOLES'), value: 'NINE_HOLES' },
      {
        label: t('competition.holesNumber.EIGHTEEN_HOLES'),
        value: 'EIGHTEEN_HOLES',
      },
    ],
    [t],
  );

  const numberOfTurnsOptions = useMemo(
    () => [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
    ],
    [],
  );

  const [updateCompetitionMutation] = useUpdateCompetitionMutation();
  const [insertCompetition] = useInsertCompetitionMutation({
    update: (cache, { data }) => {
      if (data && data.insert_competitions_one) {
        const { insert_competitions_one: newCompetition } = data;
        cache.modify({
          fields: {
            competitions: existingRefs => {
              const newRef = cache.writeFragment({
                id: `competitions:${newCompetition.id}`,
                fragment: CompetitionFragmentDoc,
                fragmentName: 'Competition',
                data: newCompetition,
              });
              return [newRef, ...existingRefs];
            },
          },
        });
      }
    },
    onCompleted: data => {
      if (data.insert_competitions_one) {
        onAdd(data.insert_competitions_one);
      } else {
        console.error('No competition data returned from insert mutation');
      }
    },
  });

  const onSubmit = async (values: FormValues) => {
    setLoading(true);
    setButtonMessage(t('common.saving'));

    try {
      const { rangeDate: rangeDateFormated, ...forMatedValues } = values;

      if (competition) {
        await updateCompetitionMutation({
          variables: {
            competitionId: competition.id,
            competition: {
              name: forMatedValues.name,
              description: forMatedValues.description,
              event_id: selectedeventId,
              location_id: selectedLocationId,
              game_type: forMatedValues.gameType,
              game_format: forMatedValues.gameFormat,
              holes_number: forMatedValues.holesNumber,
              number_of_turns: Number(forMatedValues.numberOfTurns),
              link_website: forMatedValues.linkWebsite || '',
              link_competition: forMatedValues.linkCompetition || '',
              date_start: rangeDateFormated[0],
              date_end: rangeDateFormated[1],
            },
          },
        });
      } else {
        insertCompetition({
          variables: {
            competition: {
              name: forMatedValues.name,
              description: forMatedValues.description,
              event_id: selectedeventId,
              location_id: selectedLocationId,
              game_type: forMatedValues.gameType,
              game_format: forMatedValues.gameFormat,
              holes_number: forMatedValues.holesNumber,
              number_of_turns: Number(forMatedValues.numberOfTurns),
              link_website: forMatedValues.linkWebsite || '',
              link_competition: forMatedValues.linkCompetition || '',
              date_start: rangeDateFormated[0],
              date_end: rangeDateFormated[1],
              client_id: role === 'admin' ? clientId : undefined,
              entity_type_id: entity.id,
            },
          },
        });
      }
      setButtonMessage(t('common.saved'));
      setIsFormDirty(false);
    } catch (error) {
      console.error('Error on submit', error);
      setButtonMessage(t('common.error'));
    } finally {
      setLoading(false);
      setTimeout(() => setButtonMessage(t('common.confirm')), 2000);
    }
  };

  const initialValues = {
    name: competition?.name,
    description: competition?.description,
    rangeDate,
    gameType: competition?.game_type,
    gameFormat: competition?.game_format,
    holesNumber: competition?.holes_number,
    numberOfTurns: competition?.number_of_turns,
    linkWebsite: competition?.link_website,
    linkCompetition: competition?.link_competition,
    locationId: competition?.location_id,
    eventId: competition?.event_id,
  };

  useEffect(() => {
    form.resetFields();
  }, [competition]);

  useEffect(() => {
    setIsFormDirty(false);
  }, [competition]);

  return (
    <AppForm
      form={form}
      initialValues={initialValues}
      onFinish={values => {
        setLoading(true);
        onSubmit(values).finally(() => {
          setLoading(false);
          setIsFormDirty(false); // Reset dirty state after form submission
        });
      }}
      onValuesChange={() => {
        if (!isFormDirty) {
          setIsFormDirty(true);
        }
      }}
    >
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item
            label={t('common.name')}
            name="name"
            rules={[{ required: true }]}
          >
            <Input placeholder={t('common.name')} />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            label={t('common.date_plural')}
            name="rangeDate"
            rules={[{ required: true }]}
          >
            <DateRangePicker
              showTime
              disabledBeforeDate={new Date()}
              onChange={values => setRangeDate(values)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item
            label={t('common.description')}
            name="description"
            rules={[{ required: true }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="eventId"
        label={t('competition.form.event')}
        rules={[{ required: true, message: t('competition.form.selectEvent') }]}
      >
        <Select
          placeholder={t('competition.form.selectEvent')}
          onSelect={(id: string) => setSelectedEventId(id)}
          loading={locationsLoading}
          style={{ flex: 1 }}
        >
          {eventsData &&
            eventsData.events.map(({ id, name }) => (
              <Select.Option key={id} value={id}>
                <b>{name}</b>
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="locationId"
        label={t('common.location')}
        rules={[
          { required: true, message: t('competition.form.selectGameType') },
        ]}
      >
        <Select
          placeholder={t('events.addLocation')}
          onSelect={(id: string) => setSelectedLocationId(id)}
          loading={locationsLoading}
          style={{ flex: 1 }}
        >
          {locationsData &&
            locationsData.locations.map(({ id, name, address }) => (
              <Select.Option key={id} value={id}>
                <b>{name}</b>
                {address ? ` (${address})` : null}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="gameType"
            label={t('competition.form.gameType')}
            rules={[
              { required: true, message: t('competition.form.selectGameType') },
            ]}
          >
            <Select placeholder={t('competition.form.selectGameType')}>
              {gameTypesOptions.map(option => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="gameFormat"
            label={t('competition.form.gameFormat')}
            rules={[
              {
                required: true,
                message: t('competition.form.selectGameFormat'),
              },
            ]}
          >
            <Select placeholder={t('competition.form.selectGameFormat')}>
              {gameFormatsOptions.map(option => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="holesNumber"
            label={t('competition.form.holesNumber')}
            rules={[
              {
                required: true,
                message: t('competition.form.selectHolesNumber'),
              },
            ]}
          >
            <Select placeholder={t('competition.form.selectHolesNumber')}>
              {holesNumberOptions.map(option => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="numberOfTurns"
            label={t('competition.form.numberOfTurns')}
            rules={[
              {
                required: true,
                message: t('competition.form.numberOfTurns'),
              },
            ]}
          >
            <Select placeholder={t('competition.form.selectNumberOfTurns')}>
              {numberOfTurnsOptions.map(option => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label={t('competition.form.linkWebsite')} name="linkWebsite">
        <Input placeholder={t('competition.form.linkWebsite')} />
      </Form.Item>
      <Form.Item
        label={t('competition.form.linkCompetition')}
        name="linkCompetition"
      >
        <Input placeholder={t('competition.form.linkCompetition')} />
      </Form.Item>
      <Row justify="end">
        <Button
          type={isFormDirty ? 'primary' : 'default'}
          htmlType="submit"
          icon={<SaveOutlined />}
          loading={loading}
        >
          {buttonMessage}
        </Button>
      </Row>
    </AppForm>
  );
};
