import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, Tabs } from 'antd';

import { PostFragment } from '@shared/api';

import { useLoggedClient } from 'features/auth';
import { PostSetup } from './forms';
import { PostGeofencing } from './forms/PostGeofencing';

const { TabPane } = Tabs;

export type Props = {
  visible: boolean;
  post?: PostFragment;
  onAdd: (post: PostFragment) => void;
  onClose: () => void;
};

export const PostDrawer = ({ visible, post, onAdd, onClose }: Props) => {
  const { role } = useLoggedClient();
  const { t } = useTranslation();
  const [tabActiveKey, setTabActiveKey] = useState('1');

  // Reset active key on close drawer
  useEffect(() => {
    setTabActiveKey('1');
  }, [visible]);

  // const postHasStarted = post
  //   ? new Date() >= new Date(post.date_start)
  //   : false;
  const postHasStarted = false;
  const updateDisabled = postHasStarted && role !== 'admin';

  return (
    <Drawer
      title={
        post
          ? t('posts.updatePost', {
              name: post.title,
            })
          : t('posts.createPost')
      }
      width={800}
      onClose={onClose}
      visible={visible}
      destroyOnClose
    >
      <Tabs activeKey={tabActiveKey} onChange={setTabActiveKey}>
        <TabPane tab={t('posts.setup')} key="1">
          <PostSetup
            post={post}
            onAdd={onAdd}
            updateDisabled={updateDisabled}
          />
        </TabPane>
        {post && (
          <TabPane tab={t('posts.geofencing')} key="2">
            <PostGeofencing post={post} />
          </TabPane>
        )}
      </Tabs>
    </Drawer>
  );
};
