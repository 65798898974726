import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { GetGameWinnersQuery, useGetWinnersByGainQuery } from '@shared/api';

import { displayFullNameOrEmail } from 'utils/userUtils';

interface Props {
  gainId: string;
}
const GainWinners = ({ gainId }: Props) => {
  const { i18n, t } = useTranslation();
  const { data, loading } = useGetWinnersByGainQuery({
    variables: { gain_id: gainId },
  });

  const dataSource = data?.games_played || [];

  const columns: ColumnsType<GetGameWinnersQuery['games_played'][0]> = [
    {
      title: t('common.winner'),
      dataIndex: 'user',
      render: user => displayFullNameOrEmail(user),
    },
    {
      title: t('common.event'),
      dataIndex: ['event', 'name'],
    },
    {
      title: t('table.date'),
      dataIndex: 'played_at',
      render: playedAt => {
        const date = new Date(playedAt);
        return date.toLocaleDateString(i18n.language);
      },
    },
  ];

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={dataSource}
      loading={loading}
    />
  );
};

export { GainWinners };
