import React, { useState } from 'react';
import { Switch } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

import {
  useArchiveCommunicationMutation,
  useGetCommunicationTypeByIdQuery,
  useGetCommunicationsQuery,
  useUpdateCommunicationMutation,
} from '@shared/api';

import { AppPageTable, DeleteAction } from 'components';
import { ActionProps } from 'components/AppPageTable';
import { useLoggedClient } from 'features/auth';
import { useEntity } from 'hooks/useEntity';
import {
  CommunicationDrawer,
  CommunicationDrawerProps as DrawerProps,
} from './CommunicationDrawer';
import { CommunicationData } from './types';

interface CommunicationsProps {
  typeId: string;
}

const Communications = ({ typeId }: CommunicationsProps) => {
  const { t } = useTranslation();
  const { id: clientId } = useLoggedClient();
  const entity = useEntity();
  const [drawerProps, setDrawerProps] = useState<
    Pick<DrawerProps, 'visible' | 'communication'>
  >({
    visible: false,
  });
  const { data: dataCommunicationType } = useGetCommunicationTypeByIdQuery({
    variables: { typeId },
  });
  const { data, loading } = useGetCommunicationsQuery({
    variables: {
      where: {
        _and: [
          {
            client_id: { _eq: clientId },
            type_id: { _eq: typeId },
            entity_type_id: { _eq: entity.id },
            is_archive: { _eq: false },
          },
        ],
      },
    },
  });

  const [updateCommunicationMutation] = useUpdateCommunicationMutation();
  const [archiveCommunicationMutation] = useArchiveCommunicationMutation({
    update: (cache, { data: archiveData }) => {
      if (archiveData && archiveData.update_communications_by_pk) {
        cache.modify({
          fields: {
            communications: (refs, { readField }) =>
              refs.filter(
                (ref: any) =>
                  archiveData.update_communications_by_pk?.id !==
                  readField('id', ref),
              ),
          },
        });
      }
    },
  });

  React.useEffect(() => {
    if (data && drawerProps) {
      // Keep apollo cache sync through the drawer
      setDrawerProps({
        visible: drawerProps.visible,
        communication: data.communications.find(
          communication => communication.id === drawerProps?.communication?.id,
        ),
      });
    }
  }, [data]);

  const columns: ColumnsType<CommunicationData> = [
    {
      title: t('table.name'),
      dataIndex: 'name',
    },
    {
      title: process.env.REACT_APP_ENTITY_NAME,
      dataIndex: 'events',
      render: events => (
        <span>{events.map(({ event }: any) => event.name).join(', ')}</span>
      ),
    },
    {
      title: t('table.company'),
      dataIndex: 'client',
      render: client => <span>{client ? client.company : ''}</span>,
    },
    {
      title: t('table.status'),
      dataIndex: 'is_active',
      width: '10%',
      render: (isActive, communication) => (
        <Switch
          checked={isActive}
          onClick={(_, ev) => {
            ev.stopPropagation();
            updateCommunicationMutation({
              variables: {
                communicationId: communication.id,
                communication: { is_active: !isActive },
              },
              optimisticResponse: {
                __typename: 'mutation_root',
                update_communications_by_pk: {
                  ...communication,
                  is_active: !isActive,
                  __typename: 'communications',
                },
              },
            });
          }}
        />
      ),
    },
    {
      title: '',
      key: 'action',
      width: '10%',
      onCell: () => ({ onClick: ev => ev.stopPropagation() }),
      className: 'cursor-default',
      render: communication => (
        <DeleteAction
          onDelete={() => {
            archiveCommunicationMutation({
              variables: { communicationId: communication.id },
              optimisticResponse: {
                __typename: 'mutation_root',
                update_communications_by_pk: {
                  ...communication,
                  is_archive: true,
                  __typename: 'communications',
                },
              },
            });
          }}
        />
      ),
    },
  ];

  const actions: ActionProps<CommunicationData> = [];

  return (
    <>
      <AppPageTable
        pageTitle={
          dataCommunicationType?.communications_types_by_pk?.name
            ? t(
                `${dataCommunicationType?.communications_types_by_pk?.name}.menuTitle` as any, // eslint-disable-line @typescript-eslint/no-explicit-any
              )
            : ''
        }
        onAdd={() => {
          setDrawerProps({ visible: true });
        }}
        actions={actions}
        columns={columns}
        dataSource={data?.communications}
        loading={loading}
        rowKey="id"
        onRow={communication => ({
          onClick: () =>
            setDrawerProps({
              visible: true,
              communication,
            }),
        })}
        rowClassName="cursor-pointer"
      />
      {dataCommunicationType &&
      dataCommunicationType.communications_types_by_pk ? (
        <CommunicationDrawer
          visible={drawerProps.visible}
          communication={drawerProps.communication}
          communicationType={dataCommunicationType.communications_types_by_pk}
          onAdd={communication =>
            setDrawerProps({
              visible: true,
              communication,
            })
          }
          onClose={() => setDrawerProps({ visible: false })}
        />
      ) : null}
    </>
  );
};

export { Communications };
