import React, { useState } from 'react';
import { Switch } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

import { AppPageTable, DeleteAction } from 'components';
import { ActionProps } from 'components/AppPageTable';
import { useLoggedClient } from 'features/auth';
import { useEntity } from 'hooks/useEntity';
import {
  useArchiveCompetitionMutation,
  useGetCompetitionsQuery,
  useUpdateCompetitionMutation,
} from '@shared/api';
import { formatDate } from 'utils/dateUtils';
import {
  CompetitionDrawer,
  CompetitionDrawerProps as DrawerProps,
} from './CompetitionDrawer';
import { CompetitionData } from './types';

const Competitions = () => {
  const { i18n, t } = useTranslation();
  const { id: clientId } = useLoggedClient();
  const entity = useEntity();
  const [drawerProps, setDrawerProps] = useState<
    Pick<DrawerProps, 'visible' | 'competition'>
  >({
    visible: false,
  });
  const { data, loading } = useGetCompetitionsQuery({
    variables: {
      where: {
        _and: [
          {
            client_id: { _eq: clientId },
            entity_type_id: { _eq: entity.id },
            is_archive: { _eq: false },
          },
        ],
      },
    },
  });

  const [updateCompetitionMutation] = useUpdateCompetitionMutation();
  const [archiveCompetitionMutation] = useArchiveCompetitionMutation({
    update: (cache, { data: archiveData }) => {
      if (archiveData && archiveData.update_competitions_by_pk) {
        cache.modify({
          fields: {
            competitions: (refs, { readField }) =>
              refs.filter(
                (ref: any) =>
                  archiveData.update_competitions_by_pk?.id !==
                  readField('id', ref),
              ),
          },
        });
      }
    },
  });

  React.useEffect(() => {
    if (data && drawerProps) {
      // Keep apollo cache sync through the drawer
      setDrawerProps({
        visible: drawerProps.visible,
        competition: data.competitions.find(
          competition => competition.id === drawerProps?.competition?.id,
        ),
      });
    }
  }, [data]);

  const columns: ColumnsType<CompetitionData> = [
    {
      title: t('table.name'),
      dataIndex: 'name',
    },
    {
      title: t('table.dateStart'),
      dataIndex: 'date_start',
      render: date => formatDate(new Date(date), { lang: i18n.language }),
    },
    {
      title: t('table.dateEnd'),
      dataIndex: 'date_end',
      render: date => formatDate(new Date(date), { lang: i18n.language }),
    },
    {
      title: t('table.location'),
      dataIndex: 'location',
      render: location => location.name,
    },
    {
      title: t('table.status'),
      dataIndex: 'is_active',
      width: '10%',
      render: (isActive, competition) => (
        <Switch
          checked={isActive}
          onClick={(_, ev) => {
            ev.stopPropagation();
            updateCompetitionMutation({
              variables: {
                competitionId: competition.id,
                competition: { is_active: !isActive },
              },
              optimisticResponse: {
                __typename: 'mutation_root',
                update_competitions_by_pk: {
                  ...competition,
                  is_active: !isActive,
                  __typename: 'competitions',
                },
              },
            });
          }}
        />
      ),
    },
    {
      title: '',
      key: 'action',
      width: '10%',
      onCell: () => ({ onClick: ev => ev.stopPropagation() }),
      className: 'cursor-default',
      render: competition => (
        <DeleteAction
          onDelete={() => {
            archiveCompetitionMutation({
              variables: { competitionId: competition.id },
              optimisticResponse: {
                __typename: 'mutation_root',
                update_competitions_by_pk: {
                  ...competition,
                  is_archive: true,
                  __typename: 'competitions',
                },
              },
            });
          }}
        />
      ),
    },
  ];

  const actions: ActionProps<CompetitionData> = [];

  return (
    <>
      <AppPageTable
        pageTitle={t('competition.menuTitle')}
        onAdd={() => {
          setDrawerProps({ visible: true });
        }}
        actions={actions}
        columns={columns}
        dataSource={data?.competitions}
        loading={loading}
        rowKey="id"
        onRow={competition => ({
          onClick: () => {
            setDrawerProps({
              visible: true,
              competition,
            });
          },
        })}
        rowClassName="cursor-pointer"
      />
      <CompetitionDrawer
        visible={drawerProps.visible}
        competition={drawerProps.competition}
        onAdd={competition =>
          setDrawerProps({
            visible: true,
            competition,
          })
        }
        onClose={() => setDrawerProps({ visible: false })}
      />
    </>
  );
};

export { Competitions };
