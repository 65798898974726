import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Input, Row } from 'antd';

import { ClientSignupBody } from '@shared/api';

import { AppForm, Loader, NavLink } from 'components';
import { useHistory } from 'react-router-dom';
import { authService } from '../authService';
import { EmailPasswordItems } from './EmailPasswordItems';

export const Signup = () => {
  const history = useHistory();
  const { i18n, t } = useTranslation();
  const [signupLoading, setSignupLoading] = useState(false);

  // history.replace(`/${i18n.language}/login`);

  const onSubmit = (values: ClientSignupBody) => {
    setSignupLoading(true);
    return authService.signup(values).catch(error => {
      setSignupLoading(false);
      throw error;
    });
  };

  return (
    <AppForm onFinish={onSubmit}>
      <EmailPasswordItems confirmPassword />

      <Row gutter={16}>
        <Col span="12">
          <Form.Item name="name" rules={[{ required: true }]}>
            <Input placeholder={t('user.name')} />
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item name="first_name">
            <Input placeholder={t('user.firstname')} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name={['address', 'data', 'address']}>
        <Input placeholder={t('address.address')} />
      </Form.Item>

      <Row gutter={16}>
        <Col span="12">
          <Form.Item name={['address', 'data', 'zipcode']}>
            <Input placeholder={t('address.zipcode')} />
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item name={['address', 'data', 'city']}>
            <Input placeholder={t('address.city')} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name={['address', 'data', 'country']}>
        <Input placeholder={t('address.country')} />
      </Form.Item>

      <Form.Item name="phone">
        <Input placeholder={t('user.phone')} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" block>
          {signupLoading ? <Loader /> : t('auth.signup')}
        </Button>
      </Form.Item>

      <Form.Item>
        <Button type="link" block>
          <NavLink to="/login">{t('auth.login')}</NavLink>
        </Button>
      </Form.Item>
    </AppForm>
  );
};
