import { Addresses, ClientsRolesEnum } from '@shared/api';

export const displayFullNameOrEmail = ({
  first_name: firstName,
  name,
  email,
}: {
  first_name?: string | null; // eslint-disable-line camelcase
  name?: string | null;
  email: string;
}) => {
  if (!name && !firstName) return email.substring(0, email.indexOf('@'));
  if (!name) return firstName as string;
  if (!firstName) return name.toUpperCase();
  return `${name.toUpperCase()} ${firstName}`;
};

export const displayFullNameOrId = ({
  first_name: firstName,
  name,
  id,
}: {
  first_name?: string | null; // eslint-disable-line camelcase
  name?: string | null;
  id: string;
}) => {
  if (!name && !firstName) return id;
  if (!name) return firstName as string;
  if (!firstName) return name.toUpperCase();
  return `${name.toUpperCase()} ${firstName}`;
};

export const displayAdress = (
  fullAdress?: Omit<Addresses, 'id' | 'client'> | null,
  {
    showAddress = true,
    showCity = true,
    showZipcode = true,
    showCountry = true,
  }: {
    showAddress?: boolean;
    showCity?: boolean;
    showZipcode?: boolean;
    showCountry?: boolean;
  } = {},
) => {
  if (!fullAdress) return '';
  const { address, city, zipcode, country } = fullAdress;
  // const { showAddress, showCity, showZipcode, showCountry } = options;
  const displayParts = [];
  if (showAddress && address) displayParts.push(address);
  if (showCity && city)
    displayParts.push(showZipcode && zipcode ? `${city} ${zipcode}` : city);
  else if (showZipcode && zipcode) displayParts.push(zipcode);
  if (showCountry && country) displayParts.push(country);
  return displayParts.join(', ');
};

export const getRoleColor = (role: ClientsRolesEnum) => {
  if (role === 'admin') return 'volcano';
  if (role === 'reseller') return 'blue';
  return 'green';
};
