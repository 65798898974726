import React, { useEffect, useState } from 'react';
import { Col, Form, Row, InputNumber, Slider, Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { useUpdateGameMutation } from '@shared/api';

import { AppForm, SubmitButton } from 'components';
import { AnyColorFormat, Colorpicker } from 'antd-colorpicker';
import Title from 'antd/lib/typography/Title';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { GameData } from '../games/types';

type FormValues = {
  gtc: string;
  instructions: string;
};

interface Props {
  game: GameData;
}

interface MagicSetupProps {
  blinkColors: string[];
  blinkBackground: string;
  finalColor: string;
  totalTimeValue: number;
  blinkTimeValue: number;
  isLaunched: boolean;
  currentIndex: number;
}

const initialMagicSetup: MagicSetupProps = {
  blinkColors: ['#000000', '#FFFFFF'],
  blinkBackground: 'black',
  finalColor: '#FFFFFF',
  totalTimeValue: 30,
  blinkTimeValue: 100,
  isLaunched: false,
  currentIndex: 0,
};

export const MagicSetup = ({ game }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [magicSetup, setMagicSetup] = useState<MagicSetupProps>({
    ...initialMagicSetup,
    blinkColors:
      game?.magic_random_drawings_config?.colors ||
      initialMagicSetup.blinkColors,
    finalColor:
      game?.magic_random_drawings_config?.finalColor ||
      initialMagicSetup.finalColor,
    totalTimeValue:
      game?.magic_random_drawings_config?.totalTime ||
      initialMagicSetup.totalTimeValue,
    blinkTimeValue:
      game?.magic_random_drawings_config?.blinkTime ||
      initialMagicSetup.blinkTimeValue,
  });

  const [form] = Form.useForm<FormValues>();
  const [updateGameMutation] = useUpdateGameMutation();

  useEffect(() => {
    form.resetFields();
  }, [game]);

  useEffect(() => {
    if (magicSetup.isLaunched) {
      const intervalID = setTimeout(() => {
        setMagicSetup({
          ...magicSetup,
          blinkBackground: magicSetup.blinkColors[magicSetup.currentIndex],
          currentIndex:
            (magicSetup.currentIndex + 1) % magicSetup.blinkColors.length === 0
              ? 0
              : magicSetup.currentIndex + 1,
        });
      }, magicSetup.blinkTimeValue);
      return () => clearInterval(intervalID);
    }
    return () => ({});
  });

  const onTotalTimeChange = (newValue: string | number | null | undefined) => {
    setMagicSetup({ ...magicSetup, totalTimeValue: newValue as number });
  };

  const onBlinkTimeChange = (newValue: string | number | null | undefined) => {
    setMagicSetup({ ...magicSetup, blinkTimeValue: newValue as number });
  };

  const onSubmit = async () => {
    const randomDrawingsConfig = {
      colors: magicSetup.blinkColors,
      finalColor: magicSetup.finalColor,
      totalTime: magicSetup.totalTimeValue,
      blinkTime: magicSetup.blinkTimeValue,
    };
    setLoading(true);
    if (game) {
      await updateGameMutation({
        variables: {
          gameId: game.id,
          game: { magic_random_drawings_config: randomDrawingsConfig },
        },
      });
    }
  };

  const onChange = (color: AnyColorFormat, selectedIndex: number) => {
    setMagicSetup({
      ...magicSetup,
      blinkColors: magicSetup.blinkColors.map((value, index) => {
        if (index === selectedIndex) {
          return color.hex;
        }
        return value;
      }),
    });
  };

  return (
    <AppForm
      form={form}
      onFinish={values => onSubmit().finally(() => setLoading(false))}
    >
      <Row gutter={16}>
        <Col flex={2}>
          <Title level={5}>{t('magicSetup.selectColors')}</Title>
          {magicSetup.blinkColors.map((value, index) => (
            <Space
              // eslint-disable-next-line react/no-array-index-key
              key={`blinkColors_${index}`}
              style={{ padding: 5 }}
              align="baseline"
            >
              <Colorpicker
                popup
                picker="GooglePicker"
                value={value}
                onChange={(color: AnyColorFormat) => onChange(color, index)}
                blockStyles={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                }}
              />

              <MinusCircleOutlined
                onClick={() =>
                  setMagicSetup({
                    ...magicSetup,
                    blinkColors: magicSetup.blinkColors.filter(
                      (v, i) => i !== index,
                    ),
                  })
                }
              />
            </Space>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() =>
                setMagicSetup({
                  ...magicSetup,
                  blinkColors: [...magicSetup.blinkColors, '#49348b'],
                })
              }
              block
              icon={<PlusOutlined />}
            >
              Add color
            </Button>
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Title level={5}>{t('magicSetup.finalColor')}</Title>
          <Colorpicker
            popup
            picker="GooglePicker"
            value={magicSetup.finalColor}
            onChange={(color: AnyColorFormat) =>
              setMagicSetup({
                ...magicSetup,
                finalColor: color.hex,
              })
            }
            blockStyles={{
              width: '30px',
              height: '30px',
              borderRadius: '50%',
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Title level={5}>{t('magicSetup.totalTimes')}</Title>
          <Row>
            <Col span={12}>
              <Slider
                min={30}
                max={300}
                onChange={onTotalTimeChange}
                value={
                  typeof magicSetup.totalTimeValue === 'number'
                    ? magicSetup.totalTimeValue
                    : 30
                }
              />
            </Col>
            <Col span={4}>
              <InputNumber
                min={30}
                max={300}
                style={{ margin: '0 16px' }}
                value={magicSetup.totalTimeValue}
                onChange={onTotalTimeChange}
              />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          {' '}
          <Title level={5}>{t('magicSetup.blinkTimes')}</Title>
          <Row>
            <Col span={12}>
              <Slider
                min={100}
                max={1000}
                onChange={onBlinkTimeChange}
                value={
                  typeof magicSetup.blinkTimeValue === 'number'
                    ? magicSetup.blinkTimeValue
                    : 100
                }
              />
            </Col>
            <Col span={4}>
              <InputNumber
                min={100}
                max={1000}
                style={{ margin: '0 16px' }}
                value={magicSetup.blinkTimeValue}
                onChange={onBlinkTimeChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Title level={5}>{t('magicSetup.preview')}</Title>
      <Row>
        <Col span={12}>
          <Button
            onClick={() =>
              setMagicSetup({
                ...magicSetup,
                isLaunched: !magicSetup.isLaunched,
              })
            }
          >
            {magicSetup.isLaunched
              ? t('magicSetup.stopPreview')
              : t('magicSetup.launchPreview')}
          </Button>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={16}>
          <div
            style={{
              marginTop: 20,
              marginLeft: 'auto',
              marginRight: 'auto',
              width: 300,
              height: 600,
              backgroundColor: magicSetup.blinkBackground,
            }}
          >
            <img
              src="/images/iphone-x-pictures.png"
              alt="iphone-x-pictures"
              style={{
                width: 300,
                height: 600,
                backgroundColor: magicSetup.blinkBackground,
              }}
            />
          </div>
        </Col>
      </Row>
      <Row justify="end">
        <SubmitButton loading={loading} />
      </Row>
    </AppForm>
  );
};
