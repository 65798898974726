import React, { useState } from 'react';
import { Button, Col, Divider, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { SaveOutlined } from '@ant-design/icons';

import { QrCodeFragment, useUpdateQrCodeMutation } from '@shared/api';
import { AppForm, Loader } from 'components';
import ReactQrCode from '@devmehq/react-qr-code';

interface QrCodeProps {
  qrCode?: QrCodeFragment;
  loading: boolean;
}

export const QrCodeGenerator = ({ qrCode, loading }: QrCodeProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [updateQrCodeMutation] = useUpdateQrCodeMutation();
  const [qrCodeValue, setQrCodeValue] = useState<string | undefined>(
    qrCode?.qr_code_value || undefined,
  );

  const onSubmit = async (values: any) => {
    if (qrCode) {
      await updateQrCodeMutation({
        variables: {
          qrCodeId: qrCode.id,
          qrCode: {
            ...values,
          },
        },
      });
    }
  };

  return (
    <AppForm
      form={form}
      initialValues={{ qr_code_value: qrCodeValue }}
      onFinish={onSubmit}
    >
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item
            name="qr_code_value"
            label={t('qrCode.form.label')}
            rules={[{ required: true }]}
          >
            <Input
              placeholder={t('qrCode.form.labelPlaceholder')}
              onChange={e => setQrCodeValue(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>

      {qrCodeValue && (
        <Row gutter={16}>
          <Col flex={1}>
            <ReactQrCode
              value={qrCodeValue}
              size={256}
              bgColor="#ffffff"
              fgColor="#000000"
              renderAs="svg"
              marginSize={200}
            />
          </Col>
        </Row>
      )}

      <Divider />
      <Row justify="end">
        <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
          {loading ? <Loader /> : t('common.confirm')}
        </Button>
      </Row>
    </AppForm>
  );
};
