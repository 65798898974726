import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button } from 'antd';

import { ClientLoginBody } from '@shared/api';

import { AppForm, Loader, NavLink } from 'components';
import { authService } from '../authService';
import { EmailPasswordItems } from './EmailPasswordItems';

export const Login = () => {
  const { t } = useTranslation();
  const [loginLoading, setLoginLoading] = useState(false);

  const onFinish = (values: ClientLoginBody) => {
    setLoginLoading(true);
    return authService.login(values).catch(error => {
      setLoginLoading(false);
      throw error;
    });
  };

  return (
    <AppForm onFinish={onFinish}>
      <EmailPasswordItems />

      <Form.Item>
        <Button type="primary" htmlType="submit" block>
          {loginLoading ? <Loader /> : t('auth.login')}
        </Button>
      </Form.Item>

      {/* <Form.Item>
        <Button block className="secondary-button">
          <NavLink to="/signup">{t('auth.signup')}</NavLink>
        </Button>
      </Form.Item> */}
      <Form.Item>
        <Button type="link" block>
          <NavLink to="/forgotten-password">
            {t('auth.forgottenPassword')}
          </NavLink>
        </Button>
      </Form.Item>
    </AppForm>
  );
};
