import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Switch,
  Typography,
} from 'antd';
import { CloseOutlined, CheckOutlined, SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import {
  Assets,
  GainFragmentDoc,
  useInsertGainMutation,
  useUpdateGainMutation,
} from '@shared/api';

import { AppForm, Loader, AssetInput } from 'components';
import { useLoggedClient } from 'features/auth';
import { useEntity } from 'hooks/useEntity';
import { GainData } from '../types';

const { Text } = Typography;

type FormValues = {
  name: string;
  description: string;
  // eslint-disable-next-line camelcase
  is_active: boolean;
  // eslint-disable-next-line camelcase
  is_virtual: boolean;
  stock: number;
  image?: Assets;
};

interface GainsFormDetailsProps {
  gain?: GainData;
  onCreate: (gain: GainData) => void;
}

const GainsFormDetails = ({ gain, onCreate }: GainsFormDetailsProps) => {
  const { t } = useTranslation();
  const { id: clientId, role } = useLoggedClient();
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const [stockUnlimited, setStockUnlimited] = useState<boolean>(
    gain?.stock === -9999,
  );
  const entity = useEntity();

  const [insertGainMutation] = useInsertGainMutation({
    onCompleted: data => {
      if (data && data.insert_gains_one) {
        onCreate(data.insert_gains_one);
      }
    },
    update: (cache, { data }) => {
      if (data && data.insert_gains_one) {
        cache.modify({
          fields: {
            gains: gainsRefs => {
              const newGainRef = cache.writeFragment({
                data: data.insert_gains_one,
                fragment: GainFragmentDoc,
                fragmentName: 'Gain',
              });
              return [...gainsRefs, newGainRef];
            },
          },
        });
      }
    },
  });
  const [updateGainMutation] = useUpdateGainMutation();

  const onSubmit = async ({ image, ...values }: FormValues) => {
    setLoading(true);
    if (gain) {
      await updateGainMutation({
        variables: {
          gainId: gain.id,
          gain: {
            ...values,
            stock: stockUnlimited ? -9999 : values.stock,
            image_id: image?.id,
          },
        },
      });
    } else {
      await insertGainMutation({
        variables: {
          gain: {
            ...values,
            stock: stockUnlimited ? -9999 : values.stock,
            image_id: image?.id,
            client_id: role === 'admin' ? clientId : undefined,
            entity_type_id: entity.id,
          },
        },
      });
    }
  };

  const initialValues = {
    name: gain?.name || '',
    description: gain?.description || '',
    is_active: gain ? gain.is_active : true,
    is_virtual: gain ? gain.is_virtual : false,
    stock: gain ? gain.stock : 1,
    image: gain?.image,
  };

  useEffect(() => {
    form.resetFields();
    setStockUnlimited(gain?.stock === -9999);
  }, [gain]);

  return (
    <AppForm
      form={form}
      initialValues={initialValues}
      onFinish={values => onSubmit(values).finally(() => setLoading(false))}
    >
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item
            label={t('gains.form.name')}
            name="name"
            rules={[{ required: true }]}
          >
            <Input placeholder={t('gains.form.name')} />
          </Form.Item>
          <Form.Item
            label={t('gains.form.description')}
            name="description"
            rules={[{ required: true }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Row>
            <Col flex={1}>
              <Form.Item
                label={t('gains.form.isActive')}
                name="is_active"
                valuePropName="checked"
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item
                label={t('gains.form.isVirtual')}
                name="is_virtual"
                valuePropName="checked"
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Row>
                <Col flex={1}>
                  <Form.Item label={t('gains.form.stock')} name="stock">
                    <InputNumber
                      min={1}
                      max={1000}
                      defaultValue={1}
                      disabled={stockUnlimited}
                    />
                  </Form.Item>
                </Col>
                <Col flex={2}>
                  <Space direction="vertical">
                    <Text>{t('gains.form.isStockUnmimited')}</Text>
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      onChange={() => setStockUnlimited(!stockUnlimited)}
                      checked={stockUnlimited}
                    />
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          <Form.Item name="image" label={t('gains.form.image')}>
            <AssetInput />
          </Form.Item>
        </Col>
      </Row>

      <Divider />
      <Row justify="end">
        <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
          {loading ? <Loader /> : t('common.confirm')}
        </Button>
      </Row>
    </AppForm>
  );
};

export { GainsFormDetails };
