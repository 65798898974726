import { authService } from 'features/auth';

const API_ENDPOINT = `${
  process.env.REACT_APP_AUTH_SERVER_ENDPOINT || 'http://localhost:3002'
}`;

export const magicApi = {
  launchMagicRandom: ({
    room,
    gameId,
    gainsIds,
  }: {
    room: string;
    gameId: string;
    gainsIds: string[];
  }) =>
    authService.fetchJson<void>(`${API_ENDPOINT}/magic`, 'POST', {
      room,
      gameId,
      gainsIds: JSON.stringify(gainsIds),
    }),
};
