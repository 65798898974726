import React from 'react';
import { useEntity } from 'hooks/useEntity';
import styled from 'styled-components';

interface Props {
  height: number;
  collapsed: boolean;
}

export const CollapsingLogo = ({ height, collapsed }: Props) => {
  const entity = useEntity();

  return (
    <Container $height={height}>
      <Cropped $collapsed={collapsed}>
        <Logo src={entity.logoHorizontal} alt="logo" $collapsed={collapsed} />
      </Cropped>
    </Container>
  );
};

const Container = styled.div<{ $height: number }>`
  height: ${props => props.$height}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Cropped = styled.div<{ $collapsed: boolean }>`
  overflow: hidden;
  width: ${props => (props.$collapsed ? '28px' : undefined)};
`;

const Logo = styled.img<{ $collapsed: boolean }>`
  transition: all 0.2s;
  height: ${props => (props.$collapsed ? '40px' : '60px')};
`;
