import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Divider, Button, Select } from 'antd';

import { useGetTemplatesByClientQuery, TemplateFragment } from '@shared/api';
import { SaveOutlined } from '@ant-design/icons';

import { useLoggedClient } from 'features/auth';
import { AppForm, Loader } from 'components';
import { useEntity } from 'hooks/useEntity';

const { Option } = Select;

type FormValues = {
  templateId: string;
};

interface InfosProps {
  templateId: string | undefined;
  onSave: (templateId: string) => void;
}

export const TemplateGainSelection = ({ templateId, onSave }: InfosProps) => {
  const { t } = useTranslation();
  const { id } = useLoggedClient();
  const entity = useEntity();
  const [form] = Form.useForm<FormValues>();

  const { data, loading } = useGetTemplatesByClientQuery({
    variables: { clientId: id, entityTypeId: entity.id },
  });

  const initialValues = {
    templateId,
  };

  useEffect(() => {
    form.resetFields();
  }, [templateId]);

  return (
    <AppForm
      form={form}
      initialValues={initialValues}
      onFinish={values => {
        onSave(values.templateId);
      }}
    >
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item
            label={t('templates.form.select')}
            name="templateId"
            rules={[{ required: true }]}
          >
            {data && (
              <Select style={{ width: '100%' }}>
                {data?.templates.map((template: TemplateFragment) => (
                  <Option key={template.id} value={template.id}>
                    {template.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row justify="end">
        <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
          {loading ? <Loader /> : t('common.confirm')}
        </Button>
      </Row>
    </AppForm>
  );
};
