import { useMemo } from 'react';
import { EntityType, Entities } from 'utils/entityType';

export function useEntity(): EntityType {
  const entity = useMemo(
    () => Entities[process.env.REACT_APP_ENTITY_NAME || 'FlashPlay'],
    [],
  );

  return entity;
}
