import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message, Switch, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import {
  EventCommunicationFragment,
  EventFragment,
  EventGameFragment,
  LocationFragment,
  useGetEventsQuery,
  useUpdateEventMutation,
} from '@shared/api';

import { AppPageTable, DeleteAction } from 'components';
import { ActionProps } from 'components/AppPageTable';
import { formatDate } from 'utils/dateUtils';
import { useEntity } from 'hooks/useEntity';
import { EventDrawer, Props as DrawerProps } from './EventDrawer';

export const Events = () => {
  const { i18n, t } = useTranslation();
  const entity = useEntity();

  const { data, loading } = useGetEventsQuery({
    variables: {
      where: {
        _and: [
          {
            entity_type_id: { _eq: entity.id },
            is_archive: { _eq: false },
          },
        ],
      },
    },
  });
  const [updateEventMutation] = useUpdateEventMutation({
    onError: error => message.error(error.message),
  });

  const [drawerProps, setDrawerProps] = useState<
    Pick<DrawerProps, 'visible' | 'event'>
  >({ visible: false });

  useEffect(() => {
    const { event } = drawerProps;
    if (data && event) {
      // Keep apollo cache sync through the drawer
      setDrawerProps({
        visible: drawerProps.visible,
        event: data.events.find(e => e.id === event.id),
      });
    }
  }, [data]);

  const columns: ColumnsType<EventFragment> = [
    {
      title: t('table.name'),
      dataIndex: 'name',
    },
    {
      title: t('table.dateStart'),
      dataIndex: 'date_start',
      render: date => formatDate(new Date(date), { lang: i18n.language }),
    },
    {
      title: t('table.dateEnd'),
      dataIndex: 'date_end',
      render: date => formatDate(new Date(date), { lang: i18n.language }),
    },
    {
      title: t('common.location_plural'),
      dataIndex: 'locations',
      render: (locations: Array<{ location: LocationFragment }>) =>
        locations.reduce((acc, cur, index) => {
          if (index === 0) return cur.location.name;
          return `${acc}, ${cur?.location?.name || ''}`;
        }, ''),
      ellipsis: true,
      className: 'address',
    },
    {
      title: t('events.gamesCount'),
      dataIndex: 'games',
      width: 140,
      align: 'center',
      render: (games: EventGameFragment[]) => games.length,
    },
    {
      title: t('events.communicationsCount'),
      dataIndex: 'communications',
      width: 140,
      align: 'center',
      render: (communications: EventCommunicationFragment[]) =>
        communications.length,
    },
    {
      title: t('events.favoritesCount'),
      dataIndex: 'users_favorites_events_aggregate',
      key: 'users_favorites_events_aggregate',
      width: 140,
      align: 'center',
      render: (aggregate: { aggregate: { count: number } }) =>
        aggregate.aggregate.count,
    },
    ...(process.env.REACT_APP_ENTITY_NAME === 'FlashGolf'
      ? [
          {
            title: t('events.membersCount'),
            dataIndex: 'users_members_events_aggregate',
            key: 'users_members_events_aggregate',
            width: 140,
            align: 'center' as const, // Explicitly declaring the type here
            render: (aggregate: { aggregate: { count: number } }) =>
              aggregate.aggregate.count,
          },
        ]
      : []),
    {
      title: t('common.display'),
      dataIndex: 'is_private',
      width: 120,
      align: 'center',
      render: isPrivate =>
        isPrivate ? (
          <Tag color="red">{t('table.private')}</Tag>
        ) : (
          <Tag color="green">{t('table.public')}</Tag>
        ),
    },
    {
      title: t('table.status'),
      dataIndex: 'is_active',
      width: 120,
      align: 'center',
      render: (isActive, event) => (
        <Switch
          checked={isActive}
          onClick={(_, ev) => {
            ev.stopPropagation();
            updateEventMutation({
              variables: {
                eventId: event.id,
                event: { is_active: !isActive },
              },
              optimisticResponse: {
                __typename: 'mutation_root',
                update_events_by_pk: {
                  ...event,
                  __typename: 'events',
                  is_active: !isActive,
                },
              },
            });
          }}
        />
      ),
    },
    {
      title: '',
      key: 'action',
      width: '5%',
      onCell: () => ({ onClick: ev => ev.stopPropagation() }),
      className: 'cursor-default',
      render: event => (
        <DeleteAction
          onDelete={() => {
            updateEventMutation({
              variables: {
                eventId: event.id,
                event: { is_archive: true },
              },
              update: (cache, { data: archiveData }) => {
                if (archiveData && archiveData.update_events_by_pk) {
                  cache.modify({
                    fields: {
                      events: (refs: any[], { readField }) =>
                        refs.filter(ref => readField('id', ref) !== event.id),
                    },
                  });
                }
              },
              optimisticResponse: {
                __typename: 'mutation_root',
                update_events_by_pk: {
                  ...event,
                  __typename: 'events',
                  is_archive: true,
                },
              },
            });
          }}
        />
      ),
    },
  ];

  const actions: ActionProps<EventFragment> = [];

  return (
    <>
      <AppPageTable
        pageTitle={t(`events.menuTitle`, {
          entity_name: process.env.REACT_APP_ENTITY_NAME,
        })}
        onAdd={() => {
          setDrawerProps({ visible: true });
        }}
        actions={actions}
        columns={columns}
        dataSource={data?.events}
        loading={loading}
        rowKey="id"
        onRow={event => ({
          onClick: () =>
            setDrawerProps({
              visible: true,
              event,
            }),
        })}
        rowClassName="cursor-pointer"
      />
      <EventDrawer
        {...drawerProps}
        onAdd={event =>
          setDrawerProps({
            visible: true,
            event,
          })
        }
        onClose={() => setDrawerProps({ visible: false })}
      />
    </>
  );
};
