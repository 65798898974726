import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message, Switch, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import {
  PostFragment,
  useGetPostsQuery,
  useUpdatePostMutation,
} from '@shared/api';

import { useLoggedClient } from 'features/auth';
import { AppPageTable, DeleteAction } from 'components';
import { ActionProps } from 'components/AppPageTable';
import { PostDrawer, Props as DrawerProps } from './PostDrawer';

export const Posts = () => {
  const { i18n, t } = useTranslation();
  const { id: clientId } = useLoggedClient();

  const { data, loading } = useGetPostsQuery({
    variables: {
      where: {
        _and: [
          {
            client_id: { _eq: clientId },
            is_archive: { _eq: false },
          },
        ],
      },
    },
  });
  const [updatePostMutation] = useUpdatePostMutation({
    onError: error => message.error(error.message),
  });

  const [drawerProps, setDrawerProps] = useState<
    Pick<DrawerProps, 'visible' | 'post'>
  >({ visible: false });

  useEffect(() => {
    const { post } = drawerProps;
    if (data && post) {
      // Keep apollo cache sync through the drawer
      setDrawerProps({
        visible: drawerProps.visible,
        post: data.posts.find(e => e.id === post.id),
      });
    }
  }, [data]);

  const columns: ColumnsType<PostFragment> = [
    {
      title: t('table.title'),
      dataIndex: 'title',
    },
    {
      title: t('table.type'),
      dataIndex: 'post_type',
      render: postType => <span>{postType}</span>,
    },
    {
      title: t('table.status'),
      dataIndex: 'is_active',
      width: 120,
      align: 'center',
      render: (isActive, post) => (
        <Switch
          checked={isActive}
          onClick={(_, ev) => {
            ev.stopPropagation();
            updatePostMutation({
              variables: {
                postId: post.id,
                post: { is_active: !isActive },
              },
              optimisticResponse: {
                __typename: 'mutation_root',
                update_posts_by_pk: {
                  ...post,
                  __typename: 'posts',
                  is_active: !isActive,
                },
              },
            });
          }}
        />
      ),
    },
    {
      title: '',
      key: 'action',
      width: '5%',
      onCell: () => ({ onClick: ev => ev.stopPropagation() }),
      className: 'cursor-default',
      render: post => (
        <DeleteAction
          onDelete={() => {
            updatePostMutation({
              variables: {
                postId: post.id,
                post: { is_archive: true },
              },
              update: (cache, { data: archiveData }) => {
                if (archiveData && archiveData.update_posts_by_pk) {
                  cache.modify({
                    fields: {
                      posts: (refs: any[], { readField }) =>
                        refs.filter(ref => readField('id', ref) !== post.id),
                    },
                  });
                }
              },
              optimisticResponse: {
                __typename: 'mutation_root',
                update_posts_by_pk: {
                  ...post,
                  __typename: 'posts',
                  is_archive: true,
                },
              },
            });
          }}
        />
      ),
    },
  ];

  const actions: ActionProps<PostFragment> = [];

  return (
    <>
      <AppPageTable
        pageTitle={t(`posts.menuTitle`)}
        onAdd={() => {
          setDrawerProps({ visible: true });
        }}
        actions={actions}
        columns={columns}
        dataSource={data?.posts}
        loading={loading}
        rowKey="id"
        onRow={post => ({
          onClick: () =>
            setDrawerProps({
              visible: true,
              post,
            }),
        })}
        rowClassName="cursor-pointer"
      />
      <PostDrawer
        {...drawerProps}
        onAdd={post =>
          setDrawerProps({
            visible: true,
            post,
          })
        }
        onClose={() => setDrawerProps({ visible: false })}
      />
    </>
  );
};
