import {
  ArFragmentDoc,
  useInsertArMutation,
  QuizzFragmentDoc,
  useInsertQuizzMutation,
  VoteFragmentDoc,
  useInsertVoteMutation,
  PredictionFragmentDoc,
  useInsertPredictionMutation,
  GameFragmentDoc,
  GameFragment,
  GamesInsertInput,
  useInsertQrCodeMutation,
  QrCodeFragmentDoc,
} from '@shared/api';

interface GameInsertInput {
  gameTypeAcronym: string;
  game: GamesInsertInput;
}

interface Props {
  onAdd: (game: GameFragment) => void;
}

export const useInsertGameByType = ({ onAdd }: Props) => {
  // INSERT AR
  const [insertAr] = useInsertArMutation({
    onCompleted: data => {
      if (data.insert_ar_one) {
        onAdd({ ...data.insert_ar_one, __typename: 'games' });
      }
    },
    update: (cache, { data }) => {
      if (data && data.insert_ar_one) {
        cache.modify({
          fields: {
            ar: refs => {
              const newRef = cache.writeFragment({
                data: data.insert_ar_one,
                fragment: ArFragmentDoc,
                fragmentName: 'AR',
              });
              return [newRef, ...refs];
            },
            games: refs => {
              const newRef = cache.writeFragment({
                data: {
                  ...data.insert_ar_one,
                  animations: undefined,
                  __typename: 'games',
                },
                fragment: GameFragmentDoc,
                fragmentName: 'Game',
              });
              return [newRef, ...refs];
            },
          },
        });
      }
    },
  });
  // INSERT QUIZZ
  const [insertQuizz] = useInsertQuizzMutation({
    onCompleted: data => {
      if (data.insert_quizz_one) {
        onAdd({ ...data.insert_quizz_one, __typename: 'games' });
      }
    },
    update: (cache, { data }) => {
      if (data && data.insert_quizz_one) {
        cache.modify({
          fields: {
            quizz: refs => {
              const newRef = cache.writeFragment({
                data: data.insert_quizz_one,
                fragment: QuizzFragmentDoc,
                fragmentName: 'Quizz',
              });
              return [newRef, ...refs];
            },
            games: refs => {
              const newRef = cache.writeFragment({
                data: {
                  ...data.insert_quizz_one,
                  __typename: 'games',
                },
                fragment: GameFragmentDoc,
                fragmentName: 'Game',
              });
              return [newRef, ...refs];
            },
          },
        });
      }
    },
  });
  // INSERT VOTE
  const [insertVote] = useInsertVoteMutation({
    onCompleted: data => {
      if (data.insert_votes_one) {
        onAdd({ ...data.insert_votes_one, __typename: 'games' });
      }
    },
    update: (cache, { data }) => {
      if (data && data.insert_votes_one) {
        cache.modify({
          fields: {
            votes: refs => {
              const newRef = cache.writeFragment({
                data: data.insert_votes_one,
                fragment: VoteFragmentDoc,
                fragmentName: 'Vote',
              });
              return [newRef, ...refs];
            },
            games: refs => {
              const newRef = cache.writeFragment({
                data: {
                  ...data.insert_votes_one,
                  __typename: 'games',
                },
                fragment: GameFragmentDoc,
                fragmentName: 'Game',
              });
              return [newRef, ...refs];
            },
          },
        });
      }
    },
  });
  // INSERT PREDICTION
  const [insertPrediction] = useInsertPredictionMutation({
    onCompleted: data => {
      if (data.insert_predictions_one) {
        onAdd({ ...data.insert_predictions_one, __typename: 'games' });
      }
    },
    update: (cache, { data }) => {
      if (data && data.insert_predictions_one) {
        cache.modify({
          fields: {
            predictions: refs => {
              const newRef = cache.writeFragment({
                data: data.insert_predictions_one,
                fragment: PredictionFragmentDoc,
                fragmentName: 'Prediction',
              });
              return [newRef, ...refs];
            },
            games: refs => {
              const newRef = cache.writeFragment({
                data: {
                  ...data.insert_predictions_one,
                  __typename: 'games',
                },
                fragment: GameFragmentDoc,
                fragmentName: 'Game',
              });
              return [newRef, ...refs];
            },
          },
        });
      }
    },
  });
  // INSERT QR CODE
  const [insertQrCode] = useInsertQrCodeMutation({
    onCompleted: data => {
      if (data.insert_qr_codes_one) {
        onAdd({ ...data.insert_qr_codes_one, __typename: 'games' });
      }
    },
    update: (cache, { data }) => {
      if (data && data.insert_qr_codes_one) {
        cache.modify({
          fields: {
            qr_codes: refs => {
              const newRef = cache.writeFragment({
                data: data.insert_qr_codes_one,
                fragment: QrCodeFragmentDoc,
                fragmentName: 'QrCode',
              });
              return [newRef, ...refs];
            },
            games: refs => {
              const newRef = cache.writeFragment({
                data: {
                  ...data.insert_qr_codes_one,
                  __typename: 'games',
                },
                fragment: GameFragmentDoc,
                fragmentName: 'Game',
              });
              return [newRef, ...refs];
            },
          },
        });
      }
    },
  });

  const insertGame = async (input: GameInsertInput) => {
    if (input.gameTypeAcronym === 'AR') {
      await insertAr({ variables: { ar: input.game } });
    } else if (input.gameTypeAcronym === 'QZ') {
      await insertQuizz({ variables: { quizz: input.game } });
    } else if (input.gameTypeAcronym === 'VO') {
      await insertVote({ variables: { vote: input.game } });
    } else if (input.gameTypeAcronym === 'PR') {
      await insertPrediction({ variables: { prediction: input.game } });
    } else if (input.gameTypeAcronym === 'QR') {
      await insertQrCode({ variables: { qrCode: input.game } });
    }
  };

  return [insertGame];
};
