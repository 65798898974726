import React, { useState } from 'react';
import { Switch } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

import {
  useGetGainsByClientQuery,
  useUpdateGainMutation,
  useArchiveGainMutation,
} from '@shared/api';

import { useLoggedClient } from 'features/auth';
import { AppPageTable, DeleteAction } from 'components';
import { ActionProps } from 'components/AppPageTable';
import { useEntity } from 'hooks/useEntity';
import { GainDrawer, Props as DrawerProps } from './GainDrawer';

import { GainData } from './types';

const Gains = () => {
  const { id } = useLoggedClient();
  const { t } = useTranslation();
  const entity = useEntity();
  const [drawerProps, setDrawerProps] = useState<
    Pick<DrawerProps, 'visible' | 'gain'>
  >({
    visible: false,
  });
  const { data, loading } = useGetGainsByClientQuery({
    variables: { clientId: id, entityTypeId: entity.id },
  });
  const [updateGainMutation] = useUpdateGainMutation();
  const [archiveGainMutation] = useArchiveGainMutation({
    update: (cache, { data: archiveData }) => {
      if (archiveData && archiveData.update_gains_by_pk) {
        cache.modify({
          fields: {
            gains(existingGainsRefs, { readField }) {
              return existingGainsRefs.filter(
                (gainRef: any) =>
                  archiveData.update_gains_by_pk?.id !==
                  readField('id', gainRef),
              );
            },
          },
        });
      }
    },
  });

  const columns: ColumnsType<GainData> = [
    {
      title: t('table.name'),
      dataIndex: 'name',
      key: 'name',
      width: '70%',
    },
    {
      title: t('table.status'),
      dataIndex: 'is_active',
      width: '10%',
      key: 'is_active',
      render: (isActive, gain) => (
        <Switch
          checked={isActive}
          onClick={(_, ev) => {
            ev.stopPropagation();
            updateGainMutation({
              variables: { gainId: gain.id, gain: { is_active: !isActive } },
              optimisticResponse: {
                __typename: 'mutation_root',
                update_gains_by_pk: {
                  ...gain,
                  is_active: !isActive,
                  __typename: 'gains',
                },
              },
            });
          }}
        />
      ),
    },
    {
      title: t('table.stock'),
      dataIndex: 'stock',
      key: 'stock',
      width: '10%',
      render: stock => (stock === -9999 ? '∞' : stock),
    },
    {
      title: '',
      key: 'action',
      width: '10%',
      onCell: () => ({ onClick: ev => ev.stopPropagation() }),
      className: 'cursor-default',
      render: gain => (
        <DeleteAction
          onDelete={() => {
            archiveGainMutation({
              variables: { gainId: gain.id },
              optimisticResponse: {
                __typename: 'mutation_root',
                update_gains_by_pk: {
                  ...gain,
                  is_archive: true,
                  __typename: 'gains',
                },
              },
            });
          }}
        />
      ),
    },
  ];

  const actions: ActionProps<GainData> = [];

  return (
    <>
      <AppPageTable
        pageTitle="Gains"
        onAdd={() => setDrawerProps({ visible: true })}
        actions={actions}
        columns={columns}
        dataSource={data?.gains}
        loading={loading}
        rowKey="id"
        onRow={gain => ({
          onClick: () =>
            setDrawerProps({
              visible: true,
              gain,
            }),
        })}
        rowClassName="cursor-pointer"
      />
      <GainDrawer
        visible={drawerProps.visible}
        gain={drawerProps.gain}
        onCreate={gain => setDrawerProps({ visible: true, gain })}
        onClose={() => setDrawerProps({ visible: false })}
      />
    </>
  );
};

export { Gains };
