import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  addDays,
  differenceInCalendarDays,
  endOfDay,
  startOfDay,
} from 'date-fns';
import { Slider } from 'antd';
import {
  SliderBaseProps,
  SliderRangeProps,
  SliderSingleProps,
} from 'antd/lib/slider';

interface DateSliderBaseProps {
  dateStart: Date;
  dateEnd: Date;
}

interface DateSliderSingleProps
  extends DateSliderBaseProps,
    Omit<SliderSingleProps, 'onAfterChange'> {
  onAfterChange?: (value: Date) => void;
}

interface DateSliderRangeProps
  extends DateSliderBaseProps,
    Omit<SliderRangeProps, 'onAfterChange'> {
  onAfterChange?: (value: [Date, Date]) => void;
}

type Props = DateSliderSingleProps | DateSliderRangeProps;

const isSliderSingle = (
  props: Omit<SliderSingleProps | SliderRangeProps, 'onAfterChange'>,
): props is Omit<SliderSingleProps, 'onAfterChange'> => !props.range;

export const DateSlider: React.FC<Props> = ({
  dateStart,
  dateEnd,
  ...props
}: Props) => {
  const { i18n } = useTranslation();
  const maxDays = differenceInCalendarDays(dateEnd, dateStart);

  const commonProps: SliderBaseProps = {
    max: maxDays,
    marks: {
      0: dateStart.toLocaleDateString(i18n.language, {
        day: 'numeric',
        month: 'numeric',
      }),
      [maxDays]: dateEnd.toLocaleDateString(i18n.language, {
        day: 'numeric',
        month: 'numeric',
      }),
    },
    // tipFormatter: value => {
    //   return addDays(dateStart, value || 0).toLocaleDateString(i18n.language, {
    //     day: 'numeric',
    //     month: 'numeric',
    //   });
    // },
  };

  if (isSliderSingle(props)) {
    const { range, onAfterChange, ...rest } = props;
    return (
      <Slider
        range={range}
        defaultValue={0}
        {...commonProps}
        {...rest}
        onAfterChange={value =>
          onAfterChange && onAfterChange(addDays(dateStart, value))
        }
      />
    );
  }
  const { range, onAfterChange, ...rest } = props;
  return (
    <Slider
      range={range}
      defaultValue={[0, maxDays]}
      {...commonProps}
      {...rest}
      onAfterChange={value =>
        onAfterChange &&
        onAfterChange([
          startOfDay(addDays(dateStart, value[0])),
          endOfDay(addDays(dateStart, value[1])),
        ])
      }
    />
  );
};
