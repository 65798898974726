import React from 'react';
import { AppPage } from 'components';
import { useTranslation } from 'react-i18next';

export const LiveScoring = () => {
  const { t } = useTranslation();

  return (
    <AppPage title={t('liveScoring.menuTitle')}>
      <h2>{t('AppPage.comingSoon')}</h2>
    </AppPage>
  );
};
