import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  LocationFragment,
  useGetLocationsQuery,
  useUpdateLocationMutation,
} from '@shared/api';

import { AppPageTable } from 'components';
import { ActionProps } from 'components/AppPageTable';
import { useEntity } from 'hooks/useEntity';
import { LocationDrawer, Props as DrawerProps } from './LocationDrawer';
import { getLocationsTableColumns } from './getLocationsTableColumns';

export const Locations = () => {
  const { t } = useTranslation();
  const entity = useEntity();

  const { data, loading } = useGetLocationsQuery({
    variables: { entityTypeId: entity.id },
  });
  const [updateLocation] = useUpdateLocationMutation();

  const [drawerProps, setDrawerProps] = useState<
    Pick<DrawerProps, 'visible' | 'location'>
  >({ visible: false });

  const actions: ActionProps<LocationFragment> = [];

  const archiveLocation = (location: LocationFragment) =>
    updateLocation({
      variables: { locationId: location.id, location: { is_archive: true } },
      update: (cache, { data: archiveData }) => {
        if (archiveData && archiveData.update_locations_by_pk) {
          cache.modify({
            fields: {
              locations: (refs, { readField }) =>
                refs.filter((ref: any) => readField('id', ref) !== location.id),
            },
          });
        }
      },
      optimisticResponse: {
        __typename: 'mutation_root',
        update_locations_by_pk: {
          ...location,
          is_archive: true,
        },
      },
    });
  const columns = getLocationsTableColumns(t, archiveLocation);

  return (
    <>
      <AppPageTable
        pageTitle={t(`locations.menuTitle`)}
        onAdd={() => {
          setDrawerProps({ visible: true });
        }}
        actions={actions}
        columns={columns}
        dataSource={data?.locations}
        loading={loading}
        rowKey="id"
        onRow={location => ({
          onClick: () =>
            setDrawerProps({
              visible: true,
              location,
            }),
        })}
        rowClassName="cursor-pointer"
      />
      <LocationDrawer
        {...drawerProps}
        onAdd={location =>
          setDrawerProps({
            visible: true,
            location,
          })
        }
        onClose={() => setDrawerProps({ visible: false })}
      />
    </>
  );
};
