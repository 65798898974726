import React from 'react';
import { Route } from 'react-router-dom';

import { IncludedLangSwitch } from 'components';

import { Posts } from 'features/posts/Posts';
import { LiveScoring } from 'features/live-scoring/Settings';
import { Competitions } from 'features/competitions/Competitions';
import { useLoggedClient } from '../auth';
import { Clients } from '../clients-managment/Clients';
import { Dashboard } from '../dashboard/Dashboard';
import { Events } from '../events/Events';
import { Locations } from '../locations/Locations';
import { Games } from '../games/Games';
import { Statistics } from '../statistics/Statistics';
import { Estimates } from '../estimates/Estimates';
import { Templates } from '../templates/Templates';
import { Gains } from '../gains/Gains';
import { Communications } from '../communications/Communications';
import { Goodies } from '../goodies/Goodies';
import { Settings } from '../settings/Settings';
import { Account } from '../account/Account';
import { AccountPayment } from '../account/Payment';
import { AccountInvoices } from '../account/Invoices';
import { AccountContacts } from '../account/Contacts';

export const AppRoutes = () => {
  const { role } = useLoggedClient();

  return (
    <IncludedLangSwitch>
      <Route path="/" exact>
        <Dashboard />
      </Route>
      {(role === 'admin' || role === 'reseller') && (
        <Route path="/clients">
          <Clients />
        </Route>
      )}
      <Route path="/account/payment">
        <AccountPayment />
      </Route>
      <Route path="/account/invoices">
        <AccountInvoices />
      </Route>
      <Route path="/account/contacts">
        <AccountContacts />
      </Route>
      <Route path="/account">
        <Account />
      </Route>
      <Route path="/estimates">
        <Estimates />
      </Route>
      <Route path="/events">
        <Events />
      </Route>
      <Route
        path="/games/:id"
        render={routeProps => <Games typeId={routeProps.match.params.id} />}
      />
      <Route path="/locations">
        <Locations />
      </Route>
      <Route path="/statistics">
        <Statistics />
      </Route>
      <Route path="/templates">
        <Templates />
      </Route>
      <Route path="/gains">
        <Gains />
      </Route>
      <Route
        path="/communications/:id"
        render={routeProps => (
          <Communications typeId={routeProps.match.params.id} />
        )}
      />
      <Route path="/posts">
        <Posts />
      </Route>
      <Route path="/competitions">
        <Competitions />
      </Route>
      <Route path="/live-scoring">
        <LiveScoring />
      </Route>
      <Route path="/goodies">
        <Goodies />
      </Route>
      <Route path="/settings">
        <Settings />
      </Route>
    </IncludedLangSwitch>
  );
};
