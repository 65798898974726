import React, { useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  InputNumber,
  Row,
  Select,
  Switch,
  Table,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { useLoggedClient } from 'features/auth';
import {
  GamesGainsFragment,
  useGetGainsByClientQuery,
  useInsertGameGainMutation,
  useUpdateGameGainMutation,
  useUpdateGameMutation,
} from '@shared/api';

import { Loader } from 'components';
import { evalMathExpression } from 'utils/mathUtils';
import { useEntity } from 'hooks/useEntity';
import { GameData } from '../games/types';
import { GameGainsAction } from './GameGainsAction';
import { RowData, RowUpdate } from './types';

const { Option } = Select;

interface GamesFormInstructionsProps {
  game: GameData;
}

export const GameGains = ({ game }: GamesFormInstructionsProps) => {
  const { t } = useTranslation();
  const [selectedGainId, setSelectedGainId] = useState<string | null>(null);
  const [tableUpdates, setTableUpdates] = useState<RowUpdate[]>([]);
  const entity = useEntity();

  const tableData: RowData[] = useMemo(
    () =>
      game.games_gains.map(gameGain => {
        const updates = tableUpdates.find(({ id }) => id === gameGain.gain_id);
        if (updates)
          return {
            ...gameGain,
            ...updates,
          };
        return gameGain;
      }),
    [game, tableUpdates],
  );

  const { id: clientId } = useLoggedClient();
  const [insertGameGainMutation] = useInsertGameGainMutation({
    update: (cache, { data }) => {
      if (game && data && data.insert_games_gains_one) {
        cache.modify({
          id: cache.identify(game),
          fields: {
            games_gains: gamesGainsRefs => [
              ...gamesGainsRefs,
              data.insert_games_gains_one,
            ],
          },
        });
      }
    },
  });
  const [updateGameGainMutation] = useUpdateGameGainMutation();
  const { data, loading } = useGetGainsByClientQuery({
    variables: { clientId, entityTypeId: entity.id },
  });

  const [updateGame] = useUpdateGameMutation();

  const handleNewGameGain = async () => {
    if (game && selectedGainId) {
      await insertGameGainMutation({
        variables: {
          gameGain: {
            game_id: game.id,
            gain_id: selectedGainId,
          },
        },
      });
    }
  };

  const handleChange = ({
    id,
    newStock,
    newProbability: newProbabilityPercent,
  }: RowUpdate) => {
    const newProbability =
      newProbabilityPercent &&
      evalMathExpression(newProbabilityPercent, 100, '/');

    let updateExisting = false;
    const newTableUpdates = tableUpdates.map(update => {
      if (update.id === id) {
        updateExisting = true;
        return {
          id,
          newStock: newStock === undefined ? update.newStock : newStock,
          newProbability:
            newProbability === undefined
              ? update.newProbability
              : newProbability,
        };
      }
      return update;
    });
    if (!updateExisting)
      newTableUpdates.push({
        id,
        newStock,
        newProbability,
      });

    setTableUpdates(newTableUpdates);
  };

  const columns: ColumnsType<RowData> = [
    {
      title: t('table.name'),
      dataIndex: 'gain',
      key: 'gain',
      render: gain => gain.name,
    },
    {
      title: t('table.stock'),
      dataIndex: 'stock',
      key: 'stock',
      render: (stock, { gain_id: id, gain, newStock, stock_won: stockWon }) => (
        <Row gutter={16}>
          <InputNumber
            size="small"
            min={0}
            max={gain.stock === -9999 ? undefined : stock + gain.stock}
            value={(newStock || stock) - stockWon}
            onChange={value =>
              handleChange({
                id,
                newStock: (value as number) + stockWon,
              })
            }
          />
        </Row>
      ),
    },
    {
      title: t('table.stockWon'),
      dataIndex: 'stock_won',
      key: 'stock_won',
    },
    {
      title: t('table.available'),
      key: 'rest',
      render: (gameGain: GamesGainsFragment & { newStock?: number }) =>
        gameGain.gain.stock === -9999
          ? '∞'
          : gameGain.gain.stock +
            gameGain.stock -
            (gameGain.newStock || gameGain.stock),
    },
    {
      title: t('table.probability'),
      dataIndex: 'probability',
      key: 'probability',
      render: (probability, { gain_id: id, newProbability }) => (
        <Row gutter={16}>
          <InputNumber
            size="small"
            min={0}
            max={100}
            formatter={value => `${value}%`}
            parser={value => value?.replace('%', '') || ''}
            value={evalMathExpression(
              newProbability === undefined ? probability : newProbability,
              100,
              '*',
            )}
            onChange={value =>
              handleChange({
                id,
                newProbability: value as number | undefined,
              })
            }
          />
        </Row>
      ),
    },
    {
      title: t('table.status'),
      dataIndex: 'is_active',
      key: 'status',
      render: (isActive, gameGain) => (
        <Switch
          checked={isActive}
          size="small"
          onClick={(_, ev) => {
            ev.stopPropagation();
            if (game) {
              updateGameGainMutation({
                variables: {
                  gameId: gameGain.game_id,
                  gainId: gameGain.gain_id,
                  gameGain: { is_active: !isActive },
                },
                update: (cache, { data: dataGain }) => {
                  if (dataGain && dataGain.update_games_gains_by_pk) {
                    cache.modify({
                      id: cache.identify(game),
                      fields: {
                        games_gains(existingGamesGainsRefs, { readField }) {
                          return existingGamesGainsRefs.map(
                            (gameGainRef: GamesGainsFragment) => {
                              if (
                                gameGain.gain_id ===
                                readField('gain_id', gameGainRef)
                              ) {
                                return {
                                  ...gameGainRef,
                                  is_active:
                                    dataGain?.update_games_gains_by_pk
                                      ?.is_active,
                                };
                              }
                              return gameGainRef;
                            },
                          );
                        },
                      },
                    });
                  }
                },
              });
            }
          }}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: gameGain => <GameGainsAction {...gameGain} />,
    },
  ];

  return (
    <>
      <Row gutter={16}>
        <Col span={20}>
          <Select
            defaultValue={t('gains.selectGain')}
            onChange={(value: string) => setSelectedGainId(value)}
            style={{ width: '100%' }}
          >
            {data &&
              data.gains.map(({ id, name, stock }) => (
                <Option
                  key={id}
                  value={id}
                  disabled={
                    !!game?.games_gains.filter(
                      gameGain => gameGain.gain_id === id,
                    ).length
                  }
                >
                  <b>{name}</b> ({stock === -9999 ? '∞' : stock})
                </Option>
              ))}
          </Select>
        </Col>
        <Col span={4}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<PlusOutlined />}
            style={{ width: '100%' }}
            onClick={handleNewGameGain}
          >
            {loading ? <Loader /> : t('common.add')}
          </Button>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Table size="small" columns={columns} dataSource={tableData} />
        </Col>
      </Row>
      <Row
        align="middle"
        gutter={8}
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        <Col>
          <Checkbox
            checked={game.keep_cumulated_probability}
            onChange={ev =>
              updateGame({
                variables: {
                  gameId: game.id,
                  game: {
                    keep_cumulated_probability: ev.target.checked,
                  },
                },
                optimisticResponse: {
                  __typename: 'mutation_root',
                  update_games_by_pk: {
                    __typename: 'games',
                    ...game,
                    keep_cumulated_probability: ev.target.checked,
                  },
                },
              })
            }
          />
        </Col>
        <Col flex="1">
          <Typography.Text>
            {t('games.keepCumulatedProbability')}
          </Typography.Text>
        </Col>
      </Row>
    </>
  );
};
