import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, InputNumber, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { useGetGainsByClientQuery } from '@shared/api';

import { AppForm } from 'components';
import { useLoggedClient } from 'features/auth';
import { magicApi } from 'api/magicApi';
import { useEntity } from 'hooks/useEntity';
import { GameData } from '../games/types';

const { Option } = Select;

type FormValues = {
  gains: { id: string; number: number }[];
};

interface GamesFormInfosProps {
  game: GameData;
}

export const FormRandomDrawingMagic = ({ game }: GamesFormInfosProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<FormValues>();
  const entity = useEntity();

  const { id: clientId } = useLoggedClient();

  const { data } = useGetGainsByClientQuery({
    variables: { clientId, entityTypeId: entity.id },
  });

  const lauchMagicRandomDrawing = (values: FormValues) => {
    const { gains } = values;

    const gainsIds = gains.reduce((acc, gain) => {
      const { id, number } = gain;
      const newAcc: any = [...acc];
      for (let i = 0; i < number; i++) {
        newAcc.push(id);
      }
      return newAcc;
    }, []);

    magicApi.launchMagicRandom({
      room: 'MagicRandomRoom',
      gameId: game.id,
      gainsIds,
    });
  };

  return (
    <AppForm
      form={form}
      initialValues={{ gains: [{ id: null, number: 1 }] }}
      onFinish={values => lauchMagicRandomDrawing(values)}
      autoComplete="off"
    >
      <Form.List name="gains">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, ...restField }) => (
              <div
                key={key}
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  justifyContent: 'center',
                  marginBottom: 8,
                }}
              >
                <Form.Item
                  {...restField}
                  name={[restField.name, 'id']}
                  rules={[{ required: true }]}
                  style={{ flex: 1 }}
                >
                  <Select placeholder={t('gains.selectGain')}>
                    {data &&
                      data.gains.map(({ id, name, stock }) => (
                        <Option
                          key={id}
                          value={id}
                          disabled={
                            !!game?.games_gains.find(
                              gameGain => gameGain.gain_id === id,
                            )
                          }
                        >
                          <b>{name}</b> ({stock === -9999 ? '∞' : stock})
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[restField.name, 'number']}
                  rules={[{ required: true, message: 'Missing last name' }]}
                >
                  <InputNumber min={1} max={10000} defaultValue={1} />
                </Form.Item>
                <MinusCircleOutlined
                  onClick={() => remove(restField.name)}
                  style={{
                    marginLeft: '10px',
                  }}
                />
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Ajouter les gains
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Button type="primary" htmlType="submit" icon={<PlusOutlined />}>
        {t('randomDrawing.magicLaunch')}
      </Button>
    </AppForm>
  );
};
