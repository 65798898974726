import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
} from 'antd';

import {
  Assets,
  PostFragment,
  PostFragmentDoc,
  useGetEventsQuery,
  useInsertPostMutation,
  useUpdatePostMutation,
  useGetGamesQuery,
  CommunicationFragment,
} from '@shared/api';

import { AppForm, AssetInput, SubmitButton } from 'components';
import { useLoggedClient } from 'features/auth';
import { onApolloError } from 'utils/errorUtils';
import { useEntity } from 'hooks/useEntity';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useGetCommunicationsQuery } from '@shared/api/build/types/models';
import { GameFragment } from '@shared/api/build/types/auth-models';

const { OptGroup, Option } = Select;

type FormValues = {
  title: string;
  content: string;
  postType: string;
  asset: Assets;
  eventId: string;
  linkId: string;
  locationId: string;
  dateStart: string;
  hasShareFacebook: boolean;
  hasShareInstagram: boolean;
  linkType: string;
  linkContent: string | null;
};

interface Props {
  post?: PostFragment;
  updateDisabled: boolean;
  onAdd: (post: PostFragment) => void;
}

export const PostSetup = ({ post, updateDisabled, onAdd }: Props) => {
  const { t } = useTranslation();
  const { id: clientId, role } = useLoggedClient();
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState<boolean>(false);

  const determineLinkType = (postDb: PostFragment | undefined): string => {
    if (postDb?.game_id) return 'game';
    if (postDb?.communication_id) return 'communication';
    if (postDb?.link_url) return 'link_url';
    return 'none';
  };

  const determineLinkContent = (
    postDb: PostFragment | undefined,
    linkType: string,
  ): string | null => {
    if (linkType === 'game') return postDb?.game_id || null;
    if (linkType === 'communication') return postDb?.communication_id || null;
    if (linkType === 'link_url') return postDb?.link_url || null;
    return null;
  };

  const linkDbType = useMemo(() => determineLinkType(post), [post]);

  const [selectedLinkType, setSelectedLinkType] = useState<string | null>(
    linkDbType,
  );
  const entity = useEntity();

  const { data: gamesData } = useGetGamesQuery({
    variables: {
      where: {
        _and: [
          {
            client_id: { _eq: clientId },
            is_archive: { _eq: false },
            is_active: { _eq: true },
          },
        ],
      },
    },
  });

  const gamesGroupedByType = useMemo(
    () =>
      gamesData?.games.reduce((acc, game) => {
        const typeName = game.type.name;

        if (!acc[typeName]) {
          acc[typeName] = [];
        }

        acc[typeName].push(game);

        return acc;
      }, {} as { [typeName: string]: GameFragment[] }),
    [gamesData],
  );

  const { data: communicationsData } = useGetCommunicationsQuery({
    variables: {
      where: {
        _and: [
          {
            client_id: { _eq: clientId },
            is_archive: { _eq: false },
            is_active: { _eq: true },
          },
        ],
      },
    },
  });

  const communicationsGroupedByType = useMemo(
    () =>
      communicationsData?.communications.reduce((acc, game) => {
        const typeName = game.type.name;

        if (!acc[typeName]) {
          acc[typeName] = [];
        }

        acc[typeName].push(game);

        return acc;
      }, {} as { [typeName: string]: CommunicationFragment[] }),
    [communicationsData],
  );

  const { data: eventsData } = useGetEventsQuery({
    variables: {
      where: {
        _and: [
          {
            entity_type_id: { _eq: entity.id },
            is_archive: { _eq: false },
          },
        ],
      },
    },
  });

  const [insertPost] = useInsertPostMutation({
    update: (cache, { data }) => {
      if (data && data.insert_posts_one) {
        const { insert_posts_one: newPost } = data;
        cache.modify({
          fields: {
            posts: existingRefs => {
              const newRef = cache.writeFragment({
                id: `posts:${newPost.id}`,
                fragment: PostFragmentDoc,
                fragmentName: 'Post',
                data: newPost,
              });
              return [newRef, ...existingRefs];
            },
          },
        });
      }
    },
    onCompleted: data => {
      if (data.insert_posts_one) {
        onAdd(data.insert_posts_one);
      }
    },
    onError: onApolloError,
  });
  const [updatePost] = useUpdatePostMutation({
    onError: onApolloError,
  });

  const onSubmit = async (values: FormValues) => {
    setLoading(true);
    const {
      asset,
      dateStart,
      hasShareFacebook,
      hasShareInstagram,
      locationId,
      eventId,
      linkType,
      linkContent,
      postType,
      ...formatedValues
    } = values;

    const body = {
      ...formatedValues,
      asset_id: asset ? asset.id : null,
      date_start: dateStart || null,
      has_share_facebook: hasShareFacebook,
      has_share_instagram: hasShareInstagram,
      location_id: locationId,
      post_type: postType,
      event_id: eventId,
      game_id: linkType === 'game' ? linkContent : null,
      communication_id: linkType === 'communication' ? linkContent : null,
      link_url: linkType === 'link_url' ? linkContent : null,
    };

    if (post) {
      await updatePost({
        variables: {
          postId: post.id,
          post: body,
        },
      });
    } else {
      await insertPost({
        variables: {
          post: {
            ...body,
            client_id: role === 'admin' ? clientId : undefined,
          },
        },
      });
    }
  };

  const handleLinkTypeChange = (value: string) => {
    setSelectedLinkType(value);

    let defaultLinkContentValue: string | null = null;
    if (value === 'game' && gamesData?.games[0]) {
      defaultLinkContentValue = gamesData?.games[0]?.id || null;
    } else if (
      value === 'communication' &&
      communicationsData?.communications[0]
    ) {
      defaultLinkContentValue =
        communicationsData?.communications[0]?.id || null;
    } // add more conditions if necessary

    form.setFieldsValue({
      linkContent: defaultLinkContentValue,
    });
  };

  const initialValues = {
    title: post?.title,
    content: post?.content,
    asset: post?.asset,
    locationId: post?.location?.id,
    eventId: post?.event?.id,
    linkType: linkDbType,
    linkContent: determineLinkContent(post, linkDbType),
    postType: post?.post_type || 'NEWS',
    hasShareFacebook: post?.has_share_facebook,
    hasShareInstagram: post?.has_share_instagram,
    dateStart: post?.date_start ? moment(post.date_start) : moment(new Date()),
  };

  useEffect(() => {
    form.resetFields();
  }, [post]);

  return (
    <AppForm
      form={form}
      initialValues={initialValues}
      onFinish={values => onSubmit(values).finally(() => setLoading(false))}
    >
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item
            label={t('posts.form.events', {
              entity_name: process.env.REACT_APP_ENTITY_NAME,
            })}
            name="eventId"
            rules={[{ required: true }]}
          >
            <Select>
              {eventsData &&
                eventsData.events.map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            label={t('posts.form.postType.label')}
            name="postType"
            rules={[{ required: true }]}
          >
            <Select>
              <Select.Option key="NEWS" value="NEWS">
                {t('posts.form.postType.news')}
              </Select.Option>
              <Select.Option key="MESSAGE" value="MESSAGE">
                {t('posts.form.postType.message')}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item
            label={t('posts.form.title')}
            name="title"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="dateStart" label={t('posts.form.dateStart')}>
            <DatePicker
              showTime
              // disabledDate={date => date > new Date()}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item
            label={t('posts.form.content')}
            name="content"
            rules={[{ required: true }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item
            name="asset"
            label={t('posts.form.media')}
            rules={[{ required: true, message: t('games.form.imageRequired') }]}
          >
            <AssetInput />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col style={{ display: 'flex' }}>
          <Form.Item
            name="linkType"
            label={t('posts.form.linkType')}
            style={{ display: 'flex', width: '200px' }}
          >
            <Select onChange={value => handleLinkTypeChange(value as string)}>
              <Option value="none">{t('posts.form.noLink')}</Option>
              <Option value="game">{t('games.menuTitle')}</Option>
              <Option value="communication">
                {t('communications.menuTitle')}
              </Option>
              <Option value="link_url">URL</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col style={{ display: 'flex', flex: 1 }}>
          {selectedLinkType !== 'none' && (
            <Form.Item
              name="linkContent"
              label={t('posts.form.linkInApp')}
              rules={[
                { required: true, message: t('games.form.imageRequired') },
              ]}
              style={{ display: 'flex', flex: 1 }}
            >
              {selectedLinkType === 'link_url' ? (
                <Input />
              ) : (
                <Select>
                  {selectedLinkType === 'communication' &&
                    communicationsGroupedByType &&
                    Object.keys(communicationsGroupedByType).map(typeName => (
                      <OptGroup
                        key={typeName}
                        label={t(`${typeName}.menuTitle` as any)} // eslint-disable-line @typescript-eslint/no-explicit-any
                      >
                        {communicationsGroupedByType[typeName].map(
                          ({ id, name }) => (
                            <Option key={id} value={id}>
                              {name}
                            </Option>
                          ),
                        )}
                      </OptGroup>
                    ))}
                  {selectedLinkType === 'game' &&
                    gamesGroupedByType &&
                    Object.keys(gamesGroupedByType).map(typeName => (
                      <OptGroup
                        key={typeName}
                        label={t(`${typeName}.menuTitle` as any)} // eslint-disable-line @typescript-eslint/no-explicit-any
                      >
                        {gamesGroupedByType[typeName].map(({ id, name }) => (
                          <Option key={id} value={id}>
                            {name}
                          </Option>
                        ))}
                      </OptGroup>
                    ))}
                </Select>
              )}
            </Form.Item>
          )}
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item
            label={t('posts.form.shareFacebook')}
            name="hasShareFacebook"
            valuePropName="checked"
          >
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            label={t('posts.form.shareInstagram')}
            name="hasShareInstagram"
            valuePropName="checked"
          >
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row justify="end">
        <SubmitButton loading={loading} disabled={updateDisabled} />
      </Row>
    </AppForm>
  );
};
