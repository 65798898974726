import React, { useEffect, useState } from 'react';
import { Drawer, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  useGetInfoSpecificsLazyQuery,
  useGetPhotoSpecificsLazyQuery,
  useGetSurveySpecificsLazyQuery,
} from '@shared/api';

import { isInfo } from 'utils/infoUtils';
import { isPhoto } from 'utils/photoUtils';
import { isSurvey } from 'utils/surveyUtils';
import { TemplateInfoSelection } from 'features/templates-selection/TemplateInfoSelection';
import { isCompetition } from 'utils/competitionUtils';
import { CommunicationData, CommunicationTypeData } from './types';
import { CommunicationFormSetup } from './Form/Setup';
import { PhotoForeground } from '../photos/PhotoForeground';
import { Survey } from '../surveys/Survey';

const { TabPane } = Tabs;

export type CommunicationDrawerProps = {
  visible: boolean;
  communication?: CommunicationData;
  communicationType: CommunicationTypeData;
  onAdd: (communication: CommunicationData) => void;
  onClose: () => void;
};

export const CommunicationDrawer = ({
  visible,
  communication,
  communicationType,
  onAdd,
  onClose,
}: CommunicationDrawerProps) => {
  const { t } = useTranslation();
  const [tabActiveKey, setTabActiveKey] = useState('1');
  // INFO
  const [getInfoSpecifics, { data: infoData }] = useGetInfoSpecificsLazyQuery();
  // PHOTOS
  const [
    getPhotoSpecifics,
    { data: photoData },
  ] = useGetPhotoSpecificsLazyQuery();
  // SURVEYS
  const [
    getSurveySpecifics,
    { data: surveyData, loading: surveyQuestionLoading },
  ] = useGetSurveySpecificsLazyQuery();

  useEffect(() => {
    if (communication && isInfo(communication))
      getInfoSpecifics({ variables: { id: communication.id } });
    if (communication && isPhoto(communication))
      getPhotoSpecifics({ variables: { id: communication.id } });
    if (communication && isSurvey(communication))
      getSurveySpecifics({ variables: { id: communication.id } });
  }, [communication]);

  return (
    <Drawer
      title="communication"
      width={720}
      onClose={onClose}
      visible={visible}
      destroyOnClose
    >
      <Tabs activeKey={tabActiveKey} onChange={setTabActiveKey}>
        <TabPane tab={t('communications.setup')} key="1">
          <CommunicationFormSetup
            communication={communication}
            communicationType={communicationType}
            onAdd={onAdd}
          />
        </TabPane>
        {communication && isInfo(communication) ? (
          <TabPane tab={t('common.template')} key="2">
            <TemplateInfoSelection
              info={{
                ...communication,
                ...infoData?.infos_by_pk,
                __typename: 'infos',
              }}
            />
          </TabPane>
        ) : null}
        {communication && isPhoto(communication) ? (
          <TabPane tab={t('communications.foreground')} key="2">
            <PhotoForeground
              photo={{
                ...communication,
                foreground: photoData?.photos_by_pk?.foreground,
                __typename: 'photos',
              }}
            />
          </TabPane>
        ) : null}
        {communication && isSurvey(communication) ? (
          <TabPane tab={t('communications.questions')} key="2">
            <Survey
              survey={{
                ...communication,
                __typename: 'surveys',
                surveys_questions:
                  surveyData?.surveys_by_pk?.surveys_questions || [],
              }}
              loading={surveyQuestionLoading}
            />
          </TabPane>
        ) : null}
      </Tabs>
    </Drawer>
  );
};
