import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tabs,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';

import {
  GoodieFragmentDoc,
  Goodies,
  useInsertGoodieMutation,
  useUpdateGoodieMutation,
} from '@shared/api';

import { AppForm, DateRangePicker, Loader, Upload } from 'components';
import { useLoggedClient } from 'features/auth';
import { supportedLngs } from 'i18n/config';
import { useEntity } from 'hooks/useEntity';
import { GoodieData } from '../types';

const { TabPane } = Tabs;

type FormValues = Pick<Goodies, 'name' | 'description' | 'image'> & {
  rangeDate: string[];
};

interface Props {
  goodie?: GoodieData;
  onAdd: (goodie: GoodieData) => void;
}

export const GoodieFormSetup = ({ goodie, onAdd }: Props) => {
  const { t } = useTranslation();
  const { id: clientId, role } = useLoggedClient();
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>(
    goodie?.description ? Object.keys(goodie.description)[0] : '',
  );
  const entity = useEntity();

  const [updateGoodie] = useUpdateGoodieMutation();
  const [insertGoodie] = useInsertGoodieMutation({
    onCompleted: data => {
      if (data.insert_goodies_one) {
        onAdd(data.insert_goodies_one);
      }
    },
    update: (cache, { data }) => {
      if (data && data.insert_goodies_one) {
        cache.modify({
          fields: {
            goodies: refs => {
              const newRef = cache.writeFragment({
                data: data.insert_goodies_one,
                fragment: GoodieFragmentDoc,
                fragmentName: 'Goodie',
              });
              return [...refs, newRef];
            },
          },
        });
      }
    },
  });

  const [descriptionLngs, setDescriptionLngs] = useState(
    Object.keys(goodie?.description || {}),
  );
  const availableLngs = supportedLngs.filter(l => !descriptionLngs.includes(l));
  const [newDescriptionLngModal, setNewDescriptionLngModal] = useState<{
    visible: boolean;
    availableLngs: string[];
    selectedLng: string;
  }>({
    visible: false,
    availableLngs: [],
    selectedLng: '',
  });

  const onSubmit = async (values: FormValues) => {
    setLoading(true);
    const { rangeDate, image, ...formatedValues } = values;
    const body = {
      ...formatedValues,
      date_start: rangeDate[0],
      date_end: rangeDate[1],
      client_id: role === 'admin' ? clientId : undefined,
      image_id: image && image.id,
    };
    if (goodie) {
      await updateGoodie({
        variables: {
          goodieId: goodie.id,
          goodie: body,
        },
      });
    } else {
      insertGoodie({
        variables: { goodie: { ...body, entity_type_id: entity.id } },
      });
    }
  };

  const initialValues = {
    name: goodie?.name,
    description: goodie?.description,
    rangeDate: goodie
      ? [new Date(goodie.date_start), new Date(goodie.date_end)]
      : undefined,
    image: goodie?.image,
  };

  useEffect(() => {
    form.resetFields();
  }, [goodie]);

  return (
    <AppForm
      form={form}
      initialValues={initialValues}
      onFinish={values => onSubmit(values).finally(() => setLoading(false))}
    >
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item
            label={t('common.name')}
            name="name"
            rules={[{ required: true }]}
          >
            <Input placeholder={t('common.name')} />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            label={t('games.form.dateStart')}
            name="rangeDate"
            rules={[{ required: true }]}
          >
            <DateRangePicker />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item label={t('games.form.description')}>
            <Tabs
              type="editable-card"
              size="small"
              hideAdd={!availableLngs.length}
              onChange={(currentActiveKey: string) => {
                setActiveKey(currentActiveKey);
              }}
              activeKey={activeKey}
              onEdit={(tabkey, action) => {
                if (action === 'remove' && typeof tabkey === 'string') {
                  const descLngs = descriptionLngs.filter(l => l !== tabkey);
                  setDescriptionLngs(descLngs);
                  setActiveKey(descLngs.length > 0 ? descLngs[0] : '');
                } else if (action === 'add') {
                  setNewDescriptionLngModal({
                    visible: true,
                    availableLngs,
                    selectedLng: availableLngs[0],
                  });
                }
              }}
            >
              {descriptionLngs.map(lng => (
                <TabPane tab={lng} key={lng} closable forceRender>
                  <Form.Item name={['description', lng]} initialValue="">
                    <Input.TextArea rows={4} />
                  </Form.Item>
                </TabPane>
              ))}
            </Tabs>
          </Form.Item>
          <Modal
            title={t('language')}
            visible={newDescriptionLngModal.visible}
            onOk={() => {
              setDescriptionLngs([
                ...descriptionLngs,
                newDescriptionLngModal.selectedLng,
              ]);
              setNewDescriptionLngModal({
                ...newDescriptionLngModal,
                visible: false,
              });
              setActiveKey(newDescriptionLngModal.selectedLng);
            }}
            onCancel={() =>
              setNewDescriptionLngModal({
                ...newDescriptionLngModal,
                visible: false,
              })
            }
          >
            <Select
              value={newDescriptionLngModal.selectedLng}
              onChange={value =>
                setNewDescriptionLngModal({
                  ...newDescriptionLngModal,
                  selectedLng: value.toString(),
                })
              }
            >
              {newDescriptionLngModal.availableLngs.map(lng => (
                <Select.Option key={lng} value={lng}>
                  {
                    t(`languages.${lng}` as any) // eslint-disable-line @typescript-eslint/no-explicit-any
                  }
                </Select.Option>
              ))}
            </Select>
          </Modal>

          <Row>
            <Col flex={1}>
              <Form.Item name="image" label={t('ar.assetsTypes.image')}>
                <Upload alone withCrop cropAspect={4 / 3} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider />
      <Row justify="end">
        <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
          {loading ? <Loader /> : t('common.confirm')}
        </Button>
      </Row>
    </AppForm>
  );
};
