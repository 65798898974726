import React from 'react';
import { useTranslation } from 'react-i18next';
import { AutoComplete, Button, Dropdown, Input, Menu } from 'antd';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

interface ActionProps {
  text: string;
  onClick: () => void;
}
interface Props extends ChildrenProps {
  title: string;
  addText?: string;
  onAdd?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  actions?: ActionProps[];
  numberRowsSelected?: number;
}

export const AppPage = ({
  title,
  addText,
  onAdd,
  actions = [],
  numberRowsSelected,
  children,
  onSearch,
}: Props & { onSearch?: (value: string) => void }) => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader>
        <PageTitle>{title}</PageTitle>
        <Button type="primary" icon={<PlusOutlined />} onClick={onAdd}>
          {addText || t('common.add')}
        </Button>
      </PageHeader>

      <ActionsLayout>
        <ActionsLeftContainer>
          <Dropdown
            placement="bottomLeft"
            trigger={['click']}
            disabled={!numberRowsSelected}
            overlay={
              <Menu>
                {actions.length ? (
                  actions.map(({ text, onClick }) => (
                    <Menu.Item key={text} onClick={onClick}>
                      {text}
                    </Menu.Item>
                  ))
                ) : (
                  <Menu.Item>{t('AppPage.noGroupedActions')}</Menu.Item>
                )}
              </Menu>
            }
          >
            <Actions>
              {t('AppPage.groupedActions')} <DownOutlined />
            </Actions>
          </Dropdown>
          {numberRowsSelected ? (
            <SelectionCount>
              {t('AppPage.selectionCount', { count: numberRowsSelected })}
            </SelectionCount>
          ) : (
            ''
          )}
        </ActionsLeftContainer>
        <SearchBar>
          <Input.Search placeholder="Rechercher" onSearch={onSearch} />
        </SearchBar>
      </ActionsLayout>

      <PageContent>{children}</PageContent>
    </>
  );
};

const PageHeader = styled.div`
  margin-top: 1.2rem;
  display: flex;
  align-items: center;
  color: #4a4a4a;
`;

const PageTitle = styled.div`
  flex: 1;
  font-size: 2rem;
  font-weight: bold;
`;

const ActionsLayout = styled.div`
  margin: 0.25rem 0 1rem;
  padding: 24px;
  background-color: white;
  display: flex;
  align-items: center;
`;
const ActionsLeftContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  min-width: 0;
`;
const SelectionCount = styled.span`
  margin: 0 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const SearchBar = styled(AutoComplete)`
  width: 16rem;
`;
const Actions = styled(Button)`
  width: 12rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PageContent = styled.div`
  padding: 24px;
  flex: 1;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
