import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import {
  Button,
  Col,
  Drawer,
  DrawerProps,
  Form,
  InputNumber,
  Row,
  Select,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import {
  GetGameRandomDrawingsDocument,
  RandomDrawingFragment,
  RandomDrawingFragmentDoc,
  useGetGainsByClientQuery,
} from '@shared/api';

import { gamesApi } from 'api/gamesApi';
import { AppForm, DatePicker, SubmitButton } from 'components';
import { useLoggedClient } from 'features/auth';
import { useEntity } from 'hooks/useEntity';

interface Props extends DrawerProps {
  gameId: string;
  gameType: string;
  onClose: () => void;
}

interface FormValues {
  dateStart: Date;
  dateEnd: Date;
  gains: Array<{ id: string; quantity: number }>;
  minScore: number;
}

export const NewRandomDrawingDrawer = ({
  gameId,
  gameType,
  onClose,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const apolloClient = useApolloClient();
  const { id: clientId } = useLoggedClient();
  const entity = useEntity();
  const [form] = Form.useForm<FormValues>();
  const { data: gainsData, loading: gainsLoading } = useGetGainsByClientQuery({
    variables: { clientId, entityTypeId: entity.id },
  });
  const availableGains = gainsData?.gains.filter(g => g.stock) || [];

  const [loading, setLoading] = useState(false);

  const initialValues = {
    dateStart: undefined,
    dateEnd: undefined,
    gains: [],
    minScore: undefined,
  };

  const onFinish = async (values: FormValues) => {
    setLoading(true);
    const randomDrawings = await gamesApi.randomDrawing(gameType, {
      gameId,
      ...values,
    });

    const query = GetGameRandomDrawingsDocument;
    const existingRefs = apolloClient.readQuery({
      query,
      variables: { gameId },
    });
    randomDrawings.forEach(rd =>
      apolloClient.writeFragment({
        id: apolloClient.cache.identify(rd),
        data: rd,
        fragment: RandomDrawingFragmentDoc,
        fragmentName: 'RandomDrawing',
      }),
    );
    apolloClient.writeQuery({
      query,
      variables: { gameId },
      data: {
        random_drawings: [...existingRefs.random_drawings, ...randomDrawings],
      },
    });

    // const uniqueWinners = (randomDrawings as any).reduce(
    //   (acc: any, drawing: any) => {
    //     if (!acc.ids.has(drawing.winner.id)) {
    //       acc.ids.add(drawing.winner.id);
    //       acc.winners.push(drawing.winner);
    //     }
    //     return acc;
    //   },
    //   { ids: new Set(), winners: [] },
    // ).winners;

    form.resetFields();
    onClose();
  };

  return (
    <Drawer
      width={420}
      title={t('randomDrawing.create')}
      className="secondary"
      onClose={onClose}
      {...rest}
    >
      <AppForm
        form={form}
        initialValues={initialValues}
        onFinish={values => onFinish(values).finally(() => setLoading(false))}
        style={{ flex: '1 0', display: 'flex', flexDirection: 'column' }}
      >
        <FormContainer>
          <Form.Item
            name="dateStart"
            label={t('randomDrawing.form.dateStart')}
            tooltip={t('randomDrawing.form.dateStartHelp')}
          >
            <DatePicker disabledDate={date => date > new Date()} />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.dateStart !== currentValues.dateStart
            }
          >
            {({ getFieldValue }) => (
              <Form.Item
                name="dateEnd"
                label={t('randomDrawing.form.dateEnd')}
                tooltip={t('randomDrawing.form.dateEndHelp')}
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.dateStart !== currentValues.dateStart
                }
              >
                <DatePicker
                  disabledDate={date => date < getFieldValue('dateStart')}
                />
              </Form.Item>
            )}
          </Form.Item>
          {gameType.toLowerCase() === 'quizz' && (
            <Form.Item label={t('randomDrawing.form.minScore')} name="minScore">
              <InputNumber />
            </Form.Item>
          )}
          <Form.Item label={t('randomDrawing.form.gains')}>
            <Form.List
              name="gains"
              rules={[
                {
                  validator: async (_, gains: Array<{ id: string }>) => {
                    if (!gains || !gains.length)
                      return Promise.reject(
                        new Error(
                          t('randomDrawing.form.errors.minGains', { count: 1 }),
                        ),
                      );

                    const gainsIds = gains.map(gain => gain?.id);
                    if (new Set(gainsIds).size !== gainsIds.length)
                      return Promise.reject(
                        new Error(t('randomDrawing.form.errors.duplicateGain')),
                      );

                    return Promise.resolve();
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ name, ...restField }) => (
                    <Row
                      key={restField.key}
                      gutter={12}
                      style={{ margin: '8px 0' }}
                    >
                      <Col span="17">
                        <Form.Item
                          name={[name, 'id']}
                          {...restField}
                          rules={[{ required: true }]}
                          noStyle
                        >
                          <Select
                            loading={gainsLoading}
                            onSelect={() => form.validateFields(['gains'])}
                          >
                            {availableGains
                              .sort((a, b) => {
                                if (a.name < b.name) {
                                  return -1;
                                }
                                if (a.name > b.name) {
                                  return 1;
                                }
                                return 0;
                              })
                              .map(gain => (
                                <Select.Option key={gain.id} value={gain.id}>
                                  {gain.name} (
                                  {gain.stock === -9999 ? '∞' : gain.stock})
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span="5">
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.gains[name]?.id !==
                            currentValues.gains[name]?.id
                          }
                        >
                          {({ getFieldValue }) => {
                            const selectedGainId = getFieldValue([
                              'gains',
                              name,
                              'id',
                            ]);
                            const selectedGain = availableGains.find(
                              g => g.id === selectedGainId,
                            );
                            let maxQuantity: number | undefined;
                            if (!selectedGain) maxQuantity = 1;
                            else if (selectedGain.stock !== -9999)
                              maxQuantity = selectedGain.stock;

                            return (
                              <Form.Item
                                name={[name, 'quantity']}
                                {...restField}
                                noStyle
                              >
                                <InputNumber
                                  min={1}
                                  max={maxQuantity}
                                  style={{ width: 'unset' }}
                                />
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                      <Col
                        span="2"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(name)}
                            style={{ fontSize: 16 }}
                          />
                        ) : null}
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() =>
                        add({ id: availableGains[0]?.id, quantity: 1 })
                      }
                      icon={<PlusOutlined />}
                      block
                    >
                      {t('randomDrawing.form.addGain')}
                    </Button>

                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        </FormContainer>
        <SubmitButton
          size="large"
          loading={loading}
          style={{ marginTop: '0.5rem' }}
        />
      </AppForm>
    </Drawer>
  );
};

const FormContainer = styled.div`
  flex: 1;
`;
