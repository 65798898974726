import React, { useEffect, useState } from 'react';
import { Divider, Row, Table } from 'antd';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';

import {
  EventCommunicationFragment,
  EventGameFragment,
  useUpdateEventGameMutation,
  useUpdateEventCommunicationMutation,
} from '@shared/api';

import { SubmitButton } from 'components';

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#999',
    }}
  />
));

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableBody = SortableContainer((props: any) => <tbody {...props} />);

export const EventOrganize = ({ event }: any) => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState<any[]>([]);

  const [updateEventGameMutation] = useUpdateEventGameMutation();
  const [
    updateEventCommunicationMutation,
  ] = useUpdateEventCommunicationMutation();

  useEffect(() => {
    setDataSource(
      [...event.games, ...event.communications]
        .sort((a, b) => a.order - b.order)
        .map((item: any, index: number) => ({ ...item, order: index })),
    );
  }, [event]);

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex)
        .filter(el => !!el)
        .map((el: any, index: number) => ({
          ...el,
          order: index,
        }));
      setDataSource(newData);
    }
  };

  const DraggableContainer = (props: any) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      x => x.order === restProps['data-row-key'],
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const saveOrder = () => {
    dataSource.forEach(el => {
      if (el.game) {
        updateEventGameMutation({
          variables: {
            eventId: el.event_id,
            gameId: el.game_id,
            event: { order: el.order },
          },
        });
      } else {
        updateEventCommunicationMutation({
          variables: {
            eventId: el.event_id,
            communicationId: el.communication_id,
            event: { order: el.order },
          },
        });
      }
    });
  };

  const columns: ColumnsType<EventGameFragment | EventCommunicationFragment> = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: t('table.name'),
      render: data => (data.game ? data.game.name : data.communication.name),
    },
    {
      title: t('table.type'),
      render: data =>
        data.game
          ? t(`${data.game.type.name}.menuTitle` as any)
          : t(`${data.communication.type.name}.menuTitle` as any),
    },
  ];

  return (
    <>
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey="order"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
      <Divider />
      <Row justify="end">
        <SubmitButton onClick={saveOrder} />
      </Row>
    </>
  );
};
