import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, Tabs } from 'antd';

import { EventFragment } from '@shared/api';

import { useLoggedClient } from 'features/auth';
import {
  EventCommunications,
  EventGames,
  EventGoodies,
  EventLocations,
  EventSetup,
} from './forms';
import { EventOrganize } from './forms/EventOrganize';
import { EventMembers } from './EventMembers';

const { TabPane } = Tabs;

export type Props = {
  visible: boolean;
  event?: EventFragment;
  onAdd: (event: EventFragment) => void;
  onClose: () => void;
};

export const EventDrawer = ({ visible, event, onAdd, onClose }: Props) => {
  const { role } = useLoggedClient();
  const { t } = useTranslation();
  const [tabActiveKey, setTabActiveKey] = useState('1');

  // Reset active key on close drawer
  useEffect(() => {
    setTabActiveKey('1');
  }, [visible]);

  // const eventHasStarted = event
  //   ? new Date() >= new Date(event.date_start)
  //   : false;
  const eventHasStarted = false;
  const updateDisabled = eventHasStarted && role !== 'admin';
  const updateDisabledReason = t('events.errors.noUpdateAfterStart');

  return (
    <Drawer
      title={
        event
          ? t('events.updateEvent', {
              name: event.name,
              entity_name: process.env.REACT_APP_ENTITY_NAME,
            })
          : t('events.createEvent', {
              entity_name: process.env.REACT_APP_ENTITY_NAME,
            })
      }
      width={800}
      onClose={onClose}
      visible={visible}
      destroyOnClose
    >
      <Tabs activeKey={tabActiveKey} onChange={setTabActiveKey}>
        <TabPane tab={t('games.setup')} key="1">
          <EventSetup
            event={event}
            onAdd={onAdd}
            updateDisabled={updateDisabled}
            updateDisabledReason={updateDisabledReason}
          />
        </TabPane>

        <TabPane tab={t('common.location_plural')} key="2" disabled={!event}>
          {event && (
            <EventLocations
              event={event}
              updateDisabled={updateDisabled}
              updateDisabledReason={updateDisabledReason}
            />
          )}
        </TabPane>

        <TabPane tab={t('common.game_plural')} key="3" disabled={!event}>
          {event && (
            <EventGames
              event={event}
              updateDisabled={updateDisabled}
              updateDisabledReason={updateDisabledReason}
            />
          )}
        </TabPane>

        <TabPane
          tab={t('common.communication_plural')}
          key="4"
          disabled={!event}
        >
          {event && (
            <EventCommunications
              event={event}
              updateDisabled={updateDisabled}
              updateDisabledReason={updateDisabledReason}
            />
          )}
        </TabPane>

        {process.env.REACT_APP_ENTITY_NAME === 'FlashPlay' && (
          <TabPane tab={t('common.goodie_plural')} key="5" disabled={!event}>
            {event && (
              <EventGoodies
                event={event}
                updateDisabled={updateDisabled}
                updateDisabledReason={updateDisabledReason}
              />
            )}
          </TabPane>
        )}
        {process.env.REACT_APP_ENTITY_NAME === 'FlashGolf' && (
          <TabPane tab={t('common.members')} key="5" disabled={!event}>
            {event && <EventMembers eventId={event.id} />}
          </TabPane>
        )}

        <TabPane tab={t('common.organize')} key="6" disabled={!event}>
          {event && <EventOrganize event={event} />}
        </TabPane>
      </Tabs>
    </Drawer>
  );
};
