export interface EntityType {
  id: string;
  logoVertical: string;
  logoHorizontal: string;
  colors: {
    primary: string;
  };
}

export const Entities: Record<string, EntityType> = {
  FlashPlay: {
    id: 'bba20685-66e7-4eff-8643-fe41ed8f96f2',
    logoVertical: '/images/flashplay_logo_text_vertical.png',
    logoHorizontal: '/images/flashplay_logo_text_horizontal.png',
    colors: { primary: 'red' },
  },
  FlashGolf: {
    id: '20616195-5dc8-4dc2-8c10-3da75a5ca73b',
    logoVertical: '/images/flashgolf_logo_text_vertical.png',
    logoHorizontal: '/images/flashgolf_logo_text_horizontal.png',
    colors: { primary: 'green' },
  },
};
