import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Form, Divider, Button, Select, Input } from 'antd';

import {
  LocationFragmentDoc,
  PostFragment,
  useInsertLocationMutation,
  useUpdateLocationMutation,
} from '@shared/api';
import { SaveOutlined } from '@ant-design/icons';
import { FormValues as MapFormValues } from 'components/AppFormMap';

import { useLoggedClient } from 'features/auth';
import { AppForm, AppFormMap, Loader } from 'components';
import { useEntity } from 'hooks/useEntity';

const { Option } = Select;

type FormValues = MapFormValues & {
  name: string;
};

interface PostGeofencingProps {
  post?: PostFragment;
}

export const PostGeofencing = ({ post }: PostGeofencingProps) => {
  const { t } = useTranslation();
  const { id: clientId, role } = useLoggedClient();
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const entity = useEntity();

  // const [insertLocation] = useInsertLocationMutation({
  //   onCompleted: ({ insert_locations_one: newLocation }) => {
  //     // if (newLocation) onAdd(newLocation);
  //   },
  //   update: (cache, { data }) => {
  //     if (data && data.insert_locations_one) {
  //       cache.modify({
  //         fields: {
  //           locations: refs => {
  //             const newRef = cache.writeFragment({
  //               data: data.insert_locations_one,
  //               fragment: LocationFragmentDoc,
  //               fragmentName: 'Location',
  //             });
  //             return [...refs, newRef];
  //           },
  //         },
  //       });
  //     }
  //   },
  // });
  // const [updateLocation] = useUpdateLocationMutation();

  // const onSubmit = async (values: FormValues) => {
  //   setLoading(true);
  //   if (location) {
  //     await updateLocation({
  //       variables: {
  //         locationId: location.id,
  //         location: values,
  //       },
  //     });
  //   } else {
  //     insertLocation({
  //       variables: {
  //         location: {
  //           ...values,
  //           entity_type_id: entity.id,
  //           client_id: !location && role === 'admin' ? clientId : undefined,
  //         },
  //       },
  //     });
  //   }
  // };

  return (
    <AppForm
      form={form}
      initialValues={{}}
      // onFinish={values => onSubmit(values).finally(() => setLoading(false))}
    >
      <Form.Item
        label={t('common.name')}
        name="name"
        rules={[{ required: true }]}
      >
        <Input placeholder={t('common.name')} />
      </Form.Item>
      <AppFormMap form={form} />
      <Divider />
      <Row justify="end">
        <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
          {loading ? <Loader /> : t('common.confirm')}
        </Button>
      </Row>
    </AppForm>
  );
};
